import getHttpClient from "../api";
import { setCookie, eraseCookie } from "../../utils";
import { ShouldResetPassword, ShouldVerifyAccount } from "../../exceptions";
import store from "../../store";
import {
  setAllowedCompany,
  setCurrentCompany,
} from "../../store/Company/actions";
const client = getHttpClient();
const baseUrl = "/user-management";
const afterAuth = (user, days = 0) => {
  const accessToken = user["access_token"];
  setCookie("jwt", accessToken, days);
  setCookie("user", JSON.stringify(user), days);
  const companies = user.companies;
  const currentCompany = companies.find(
    (company) => company.id === user.company
  );
  store.dispatch(setCurrentCompany(currentCompany));
  store.dispatch(setAllowedCompany(companies));
};
export default {
  logout: async () => {
    eraseCookie("jwt");
    eraseCookie("user");
    localStorage.clear();
  },
  signup: async (first_name, last_name, email, mobile, password) => {
    try {
      const data = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        mobile: mobile,
        password: password,
      };
      const response = await client.post(`${baseUrl}/signup/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const userData = response.data;
      const user = {
        ...userData,
        name: userData.first_name + " " + userData.last_name,
      };
      afterAuth(user);
      return user;
    } catch (err) {
      throw err;
    }
  },
  login: async (contact, password, rememberMe) => {
    try {
      const data = {
        contact: contact,
        password: password,
      };
      const response = await client.post(`${baseUrl}/login/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const userData = response.data;
      const user = {
        ...userData,
        name: userData.first_name + " " + userData.last_name,
      };
      let days = 0;
      if (rememberMe) days = 0.04;
      afterAuth(user, days);
      return user;
    } catch (err) {
      throw err;
    }
  },
};
