import { useState, useEffect } from "react";
import React, { useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import SubmitButton from "../Buttons/Submit";
import CancelIcon from "../../assets/imgs/icons/cancel.svg";
import WarningIcon from "../../assets/imgs/icons/warning.svg";
import SearchIcon from "../../assets/imgs/icons/SearchBlue.svg";

const ConfirmPayment = ({
  title,
  message,
  selectOutstandings,
  addAsCredit,
  onResolve,
  onReject,
}) => {
  const backRef = useRef(null);

  return (
    <div
      ref={backRef}
      isOpen={backRef}
      class="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
          <div class="bg-white px-2 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start w-full">
              <div class="w-full font-roboto">
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row items-center" id="modal-title">
                    <div>
                      <img src={WarningIcon} alt="" />
                    </div>
                    <div class="text-lg leading-6 font-medium text-gray-900 ml-2">
                      {title}
                    </div>
                  </div>
                  <div className="cursor-pointer" onClick={() => onReject()}>
                    <img src={CancelIcon} alt="" />
                  </div>
                </div>
                <div className="text-sm text-grayColorText px-10">
                  {message}
                </div>
              </div>
            </div>
            <div className="flex flex-row-reverse items-center mt-8">
              <div className="flex flex-row items-center">
                {selectOutstandings && (
                  <div
                    className="px-8 text-sm font-bold cursor-pointer font-roboto text-arrowTop"
                    onClick={() => {
                      onResolve("selectOutstandings");
                    }}
                  >
                    {selectOutstandings}
                  </div>
                )}
                <div>
                  <SubmitButton
                    onClick={() => {
                      onResolve("create");
                    }}
                  >
                    {addAsCredit}
                  </SubmitButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPayment;
