import errorIcon from "./assets/imgs/icons/error.svg";
import successGreenIcon from "./assets/imgs/icons/successGreen.svg";
import successIcon from "./assets/imgs/icons/success.svg";
import format from "date-fns/format";

const setCookie = (name, value, days) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};
const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};
const eraseCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};
const formatContactDisplay = (contact) => {
  return (
    contact.substr(0, 2) +
    "*".repeat(contact.length - 4) +
    contact.substr(contact.length - 2, 2)
  );
};
const getToastErrorProps = () => {
  return {
    icon: ({ theme, type }) => (
      <img src={errorIcon} alt="" width="30" height="30" />
    ),
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  };
};

const getToastSuccessGreenProps = () => {
  return {
    icon: ({ theme, type }) => (
      <img src={successGreenIcon} alt="" width="30" height="30" />
    ),
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    type: "successGreen",
  };
};
const getToastSuccessProps = () => {
  return {
    icon: ({ theme, type }) => (
      <img src={successIcon} alt="" width="48" height="48" />
    ),
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  };
};

const getFileType = (name) => {
  const nameOfFile = name.toLowerCase();
  if (nameOfFile?.endsWith(".pdf")) {
    return "pdf";
  } else if (nameOfFile?.endsWith(".doc") || nameOfFile?.endsWith(".docx")) {
    return "word";
  } else if (
    nameOfFile?.endsWith(".xls") ||
    nameOfFile?.endsWith(".xlsx") ||
    nameOfFile?.endsWith(".xlsm")
  ) {
    return "excel";
  } else {
    return "image";
  }
};
const convertFileToBase64 = (file, cb) => {
  let reader = new FileReader();

  reader.readAsDataURL(file);

  reader.onload = function (event) {
    cb(event.target.result);
  };

  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
};
const convertUrlToFile = async (url) => {
  let response = await fetch(url);
  let blob = await response.blob();

  return new File([blob], {});
};
const formatDate = (date) => {
  return format(new Date(date), "yyyy-MM-dd");
};
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const generateAvatar = (name) => {
  if (name) {
    const names = name.split(" ");
    const first_name = names[0];
    const last_name = names[names.length - 1];
    return first_name[0].toUpperCase() + last_name[0].toUpperCase();
  } else {
    return "";
  }
};
export {
  setCookie,
  getCookie,
  eraseCookie,
  formatContactDisplay,
  getToastErrorProps,
  getToastSuccessGreenProps,
  getToastSuccessProps,
  getFileType,
  convertFileToBase64,
  convertUrlToFile,
  formatDate,
  capitalizeFirstLetter,
  generateAvatar,
};
