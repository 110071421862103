import getHttpClient from "../api";
const client = getHttpClient();

export default {
  getAllUsers: async () => {
    try {
      const response = await client.get("/users");
      const users = await response.json();
      return users;
    } catch (error) {
      throw error;
    }
  },
  singup: async (userData) => {
    try {
      const response = await client.post("/signup");
      const user = await response.json();
      return user;
    } catch (error) {
      throw error;
    }
  },
};
