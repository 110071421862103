import {
  NEXT_STEP,
  PREV_STEP,
  SET_CONTACT,
  SET_CONTACTS,
  SET_STEP,
  SET_TOKEN,
} from "./type";

export const goNext = () => {
  return {
    type: NEXT_STEP,
  };
};

export const goPrev = () => {
  return {
    type: PREV_STEP,
  };
};

export const setResetPasswordContact = (contact, contactType) => {
  return {
    type: SET_CONTACT,
    contact: contact,
    contactType: contactType,
  };
};

export const setContacts = (contacts) => {
  return {
    type: SET_CONTACTS,
    contacts: contacts,
  };
};

export const setToken = (token) => {
  return {
    type: SET_TOKEN,
    token: token,
  };
};
export const setStep = (step = 0) => {
  return {
    type: SET_STEP,
    step: step,
  };
};
