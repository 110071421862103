import "./style.scss";
const Avatar = ({ avatar }) => {
  return (
    <div class="avatar-comp">
      <div class="mj valign-text-middle roboto-bold-pacific-blue-16px">
        {avatar}
      </div>
    </div>
  );
};

export default Avatar;
