import { SET_BASE_PAYMENT_METHODS } from "./type";

export const initialState = {
  basePaymentMethods: [],
};

const PaymentMethods = (state = initialState, action) => {
  switch (action.type) {
    case SET_BASE_PAYMENT_METHODS:
      state = {
        ...state,
        basePaymentMethods: action.paymentMethods,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default PaymentMethods;
