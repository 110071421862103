import getHttpClient from "../api";
const client = getHttpClient();

export default {
  uploadAttachment: async (attachment, onUploadProgress) => {
    try {
      let formData = new FormData();
      formData.append("file", attachment);
      const response = await client.post("/dms/attachment/", formData, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          onUploadProgress(data);
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  uploadAttachmentViaUrl: async (name, url, onUploadProgress) => {
    try {
      let formData = new FormData();
      formData.append("url", url);
      formData.append("file_name", name);
      const response = await client.post("/dms/attachment/", formData, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          onUploadProgress(data);
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteAttachment: async (attachmentId) => {
    try {
      const response = await client.del(`/dms/attachment/${attachmentId}`, {});
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getPaymentMethods: async (companyId) => {
    try {
      const response = await client.get(`/dms/payment-method/${companyId}`, {});
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getAssigneeOfCompany: async (companyId) => {
    try {
      const response = await client.get(`/dms/assignee/${companyId}`, {});
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getCostCenters: async (companyId) => {
    try {
      const response = await client.get(`/dms/cost-center/${companyId}`, {});
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getVendors: async (companyId) => {
    try {
      const response = await client.get(`/dms/vendor/${companyId}`, {});
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getCustomers: async (companyId) => {
    try {
      const response = await client.get(`/dms/customer/${companyId}`, {});
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  addVendor: async (companyId, name) => {
    try {
      const vendor = { name: name };
      const response = await client.post(`/dms/vendor/${companyId}`, vendor);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  addCustomer: async (companyId, name) => {
    try {
      const vendor = { name: name };
      const response = await client.post(`/dms/customer/${companyId}`, vendor);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  createNewCashBox: async (companyId, currencyId) => {
    try {
      const cashbox = { currency: currencyId };
      const response = await client.post(
        `/dms/payment-method/${companyId}`,
        cashbox
      );
      return response.data[0];
    } catch (error) {
      throw error;
    }
  },
  getCategories: async () => {
    try {
      const response = await client.get(`/dms/client-category/`, {});
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  addCategory: async (name) => {
    try {
      const category = { name: name };
      const response = await client.post(`/dms/client-category/`, category);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getOutstandingsOfVendor: async (vendorId) => {
    try {
      const response = await client.get(
        `/dms/vendor-outstanding/${vendorId}`,
        {}
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getFiscalYear: async (companyId) => {
    try {
      const response = await client.get(
        `/user-management/company-fiscal-year/${companyId}`,
        {}
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getOutstandingsOfCustomer: async (customerId) => {
    try {
      const response = await client.get(
        `/dms/customer-outstanding/${customerId}`,
        {}
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  submitDocument: async (
    companyId,
    attachments,
    paymentStatus,
    paymentMethod,
    clientCategory,
    costCenter,
    paid_amount,
    description,
    assignees
  ) => {
    try {
      const data = {
        payment_status: paymentStatus,
        payment_method: paymentMethod || null,
        client_category: clientCategory || null,
        cost_center: costCenter || null,
        paid_amount: paid_amount || 0.0,
        description: description || null,
        attachments: attachments || [],
        assignees: assignees || [],
      };
      const response = await client.post(`/dms/document/${companyId}`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
