import { useFormContext } from "react-hook-form";
import ErrorInput from "../Error";

const RadioButtonInput = ({ name, options, serverErrors, ...props }) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  return (
    <div className="w-full">
      <div className="flex flex-row justify-between">
        {options.map((option) => {
          return (
            <div className="p-1 w-full">
              <label for={option.key}>
                <div
                  className="flex items-center justify-center w-full h-12 px-4 py-2 text-sm border rounded cursor-pointer border-borderColor font-Roboto"
                  style={{
                    backgroundColor:
                      watch(name) === option.key ? `${option.color}29` : "",
                    borderColor: watch(name) === option.key ? option.color : "",
                    color: watch(name) === option.key ? option.color : "",
                  }}
                >
                  {option.value}
                </div>
              </label>
              <input
                id={option.key}
                className="hidden"
                type="radio"
                name={name}
                value={option.key}
                hidden={true}
                {...register(name, {})}
                {...props}
              />
            </div>
          );
        })}
      </div>
      <div>
        <ErrorInput name={name} errors={errors} serverErrors={serverErrors} />
      </div>
    </div>
  );
};

export default RadioButtonInput;
