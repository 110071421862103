import React, { useCallback, useContext } from "react";

import ModalContext from "./context";

let uniqueCounter = 0;

export default function useCancellableModal(ModalComponent) {
  // called every render

  const modalContext = useContext(ModalContext);

  if (modalContext === null) {
    throw new Error(
      "Seems like <ModalProvider /> is not present in the tree above, wrap your app with it or check docs"
    );
  }

  return useCallback(
    (props) => {
      const id = uniqueCounter++;

      let onResolve;
      let onReject;

      const promise = new Promise((resolve, reject) => {
        // callbacks passed to component

        onResolve = (result) => {
          modalContext.removeModal(id);

          resolve(result);
        };

        onReject = (reason) => {
          modalContext.removeModal(id);

          reject(reason);
        };
      });

      modalContext.setModal(
        id,
        <ModalComponent onResolve={onResolve} onReject={onReject} {...props} />
      );

      const cancel = () => onReject("Closed from outside");

      return [promise, cancel];
    },
    [ModalComponent]
  );
}
