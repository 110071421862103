class ShouldResetPassword extends Error {
  constructor(...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);
  }
}
class ShouldVerifyAccount extends Error {
  constructor(...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);
  }
}

export { ShouldVerifyAccount, ShouldResetPassword };
