import * as yup from "yup";

export default yup
  .number()
  .transform((val, value) => {
    return value === "" ? 0.0 : val;
  })
  .typeError("Amount must be a number")
  .test("format", "Number with maximum 2 decimal point", (value) => {
    return /^-*[0-9,]+([.][0-9]{0,2})?$/.test(value || 0.0);
  })
  .max("100000000")
  .test("Is Zero?", "Amount can’t be zero", (value) => value !== 0)
  .positive("Amount can’t be a negative number");
