import getHttpClient from "../api";
const client = getHttpClient();

const baseUrl = "/user-management";
export default {
  setPassword: async (token, password, confirm_password) => {
    try {
      const data = {
        token: token,
        password: password,
        confirm_password: confirm_password,
      };
      const response = await client.post(`${baseUrl}/set-password`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (err) {
      throw err;
    }
  },
  resetPassword: async (token, password, confirm_password) => {
    try {
      const data = {
        token: token,
        password: password,
        confirm_password: confirm_password,
      };
      const response = await client.post(`${baseUrl}/reset-password/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (err) {
      throw err;
    }
  },
  forgetPassword: async (contact) => {
    try {
      const data = {
        contact: contact,
      };
      const response = await client.post(`${baseUrl}/forget-password/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const contacts = response.data;
      return contacts;
    } catch (err) {
      throw err;
    }
  },
  verifyMobile: async (mobile) => {
    try {
      const data = { mobile: mobile };
      const response = await client.post(
        `${baseUrl}/forget-password/mobile/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (err) {
      throw err;
    }
  },

  resendVerifyMobile: async (mobile) => {
    try {
      const data = { mobile: mobile };
      const response = await client.post(
        `${baseUrl}/forget-password/mobile/resend/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (err) {
      throw err;
    }
  },
  verifyEmail: async (email) => {
    try {
      const data = { email: email };
      const response = await client.post(
        `${baseUrl}/forget-password/email/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (err) {
      throw err;
    }
  },

  resendVerifyEmail: async (email) => {
    try {
      const data = { email: email };
      const response = await client.post(
        `${baseUrl}/forget-password/email/resend/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (err) {
      throw err;
    }
  },
  verifyCode: async (contact, code) => {
    try {
      const data = { token: code, contact: contact };
      const response = await client.post(
        `${baseUrl}/forget-password/token/validate/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (err) {
      throw err;
    }
  },
};
