import Select, { components, OptionProps } from "react-select";
import RadioButtonCheckedIcon from "../../../assets/imgs/icons/radio_button_checked.svg";
import resolveConfig from "tailwindcss/resolveConfig";
import { useFormContext, Controller } from "react-hook-form";
import AddButtonIcon from "../../../assets/imgs/icons/add.svg";
import SingleValue from "../Select/singleValue";
import Option from "../Select/option";
import customStyles from "../Select/style";
import filterOptions from "../Select/filterOptions";
import ErrorInput from "../Error";
const SelectMenuButton = ({ title, onCreate, ...props }) => {
  return (
    <>
      <components.MenuList {...props}>{props.children}</components.MenuList>
      <div>
        <div class="flex flex-row items-center place-content-start cursor-pointer text-xl py-4 px-2  border-t-2">
          <div className="mr-2">
            <img src={AddButtonIcon} alt="" />
          </div>
          <div className="text-sm leading-3" onClick={onCreate}>
            {title}
          </div>
        </div>
      </div>
    </>
  );
};
const CreateSelectInput = ({
  name,
  options,
  onCreate,
  title,
  serverErrors,
  ...props
}) => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <div>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, ref } }) => (
          <Select
            options={options}
            styles={customStyles}
            menuPortalTarget={document.body}
            isSearchable={true}
            components={{
              IndicatorSeparator: null,
              Option: Option,
              SingleValue: SingleValue,
              Input: (inputProps) => (
                <components.Input
                  {...inputProps}
                  className="react-select__input-custom"
                />
              ),
              MenuList: (props) => (
                <SelectMenuButton
                  title={title}
                  onCreate={onCreate}
                  {...props}
                />
              ),
            }}
            value={options.find((c) => c.value === value)}
            onChange={(val) => onChange(val.value)}
            filterOption={filterOptions}
            {...props}
          />
        )}
      />
      <ErrorInput name={name} errors={errors} serverErrors={serverErrors} />
    </div>
  );
};

export default CreateSelectInput;
