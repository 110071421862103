import { NEXT_STEP, PREV_STEP, SET_SIGNUP_CONTACT } from "./type";

export const initialState = {
  step: 0,
  signUpContact: null,
  signUpType: null,
};

const signup = (state = initialState, action) => {
  switch (action.type) {
    case NEXT_STEP:
      state = {
        ...state,
        step: state.step + 1,
      };
      break;
    case PREV_STEP:
      state = {
        ...state,
        step: state.step - 1,
      };
      break;
    case SET_SIGNUP_CONTACT:
      state = {
        ...state,
        signUpContact: action.contact,
        signUpType: action.contactType,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default signup;
