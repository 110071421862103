import { useState } from "react";
import React, { useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import UploadDocumentService from "../../services/UploadDocument";
import TextInput from "../Inputs/Text";
import validateTextInput from "../Inputs/Text/validation_with_numbers";
import SubmitButton from "../Buttons/Submit";
import ErrorMessage from "../ErrorMessage";
import CancelIcon from "../../assets/imgs/icons/cancel.svg";

const schema = yup
  .object({
    name: yup
      .string()
      .required("This field is required")
      .max(20, "max 20 characters"),
  })
  .required();

const CreateNewCategory = ({ message, onResolve, onReject }) => {
  const backRef = useRef(null);
  const [error, setError] = useState("");
  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const name = data.name;
      const category = await UploadDocumentService.addCategory(name);
      onResolve(category);
    } catch (error) {
      setError(error);
    }
  };
  return (
    <div
      ref={backRef}
      isOpen={backRef}
      class="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              class="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div class="inline-block align-bottom bg-white rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full">
              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start w-full ">
                  <div class="w-full">
                    <div className="flex flex-row justify-between">
                      <h3
                        class="text-xl leading-7 font-bold text-gray-900 font-poppins"
                        id="modal-title"
                      >
                        Create a new category
                      </h3>
                      <div
                        className="cursor-pointer"
                        onClick={() => onReject()}
                      >
                        <img src={CancelIcon} alt="" />
                      </div>
                    </div>
                    <div class="w-full mt-4">
                      <TextInput
                        name="name"
                        serverErrors={error}
                        placeholder="Name category"
                      />
                    </div>
                    {error?.detail && (
                      <ErrorMessage>{error.detail}</ErrorMessage>
                    )}
                  </div>
                </div>
                <div className="flex flex-row-reverse mt-4">
                  <div className="w-24">
                    <SubmitButton className="rounded-lg px-4 py-3">
                      Create
                    </SubmitButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateNewCategory;
