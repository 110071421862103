import { OPEN_FILE_VIEWER, CLOSE_FILE_VIEWER } from "./actionsType";

export const openFileViewer = (file) => ({
  type: OPEN_FILE_VIEWER,
  payload: file,
});

export const closeFileViewer = () => ({
  type: CLOSE_FILE_VIEWER,
});
