import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SelectInput from "../../Inputs/Select";
import PaymentMethodsIcon from "../../../assets/imgs/icons/paymentMethods.svg";
import UploadDocumentService from "../../../services/UploadDocument";
import { formatDate, getCookie } from "../../../utils";
import { useFormContext } from "react-hook-form";
import { ToastifySuccessGreen } from "../../../toastify";
import CashIcon from "../../../assets/imgs/icons/moneyCash.svg";
import BankIcon from "../../../assets/imgs/icons/bank.svg";
import ErrorIcon from "../../../assets/imgs/icons/errorIcon.svg";
import ErrorMessage from "../../ErrorMessage";
import { setBasePaymentMethods } from "../../../store/paymentMethods/actions";

const PaymentMethod = ({
  currentCurrency,
  baseCurrencies,
  basePaymentMethods,
  setBasePaymentMethods,
  currentCompany,
  error,
}) => {
  const [showCreateCash, setShowCreateCash] = useState(false);
  const methods = useFormContext();
  const user = JSON.parse(getCookie("user"));
  const [paymentMethods, setPaymentMethods] = useState([]);

  const createNewCashBox = async () => {
    try {
      const paymentMethod = await UploadDocumentService.createNewCashBox(
        currentCompany.id,
        currentCurrency.id
      );
      setBasePaymentMethods([
        ...basePaymentMethods,
        {
          ...paymentMethod,
          currency: baseCurrencies.find(
            (currency) => currency.id === paymentMethod.currency
          ),
        },
      ]);
      ToastifySuccessGreen(
        `${currentCurrency?.code} Cashbox Created Successfully`
      );
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (paymentMethods.length === 1) {
      methods.setValue("payment_method", paymentMethods[0].value);
    } else {
      methods.setValue("payment_method", null);
    }
  }, [paymentMethods]);
  const setPaymentMethodsOfCurrency = (currency) => {
    if (
      currency &&
      basePaymentMethods.length &&
      basePaymentMethods[0].currency
    ) {
      const paymentMethods = basePaymentMethods.filter(
        (paymentMethod) =>
          paymentMethod?.currency?.id === currency?.id ||
          paymentMethod.type === "bank"
      );
      const cashPaymentMethod = paymentMethods.find(
        (paymentMethod) => paymentMethod.type === "cash"
      );
      if (!cashPaymentMethod) {
        paymentMethods.unshift({
          id: -1,
          name: "Cash",
          balance: 0.0,
          type: "cash",
          currency: currentCurrency,
        });
      }
      const paymentMethodsOptions = paymentMethods.map((paymentMethod) => {
        return {
          value: paymentMethod.id,
          label: paymentMethod.name,
          text: `${paymentMethod.balance} ${paymentMethod?.currency?.code}`,
          icon: paymentMethod.type === "cash" ? CashIcon : BankIcon,
        };
      });
      setPaymentMethods(paymentMethodsOptions);
    }
  };
  const getPaymentMethods = async () => {
    try {
      const paymentMethods = await UploadDocumentService.getPaymentMethods(
        currentCompany.id
      );
      paymentMethods.map((paymentMethod) => {
        return {
          ...paymentMethod,
          currency: baseCurrencies.find(
            (currency) => currency.id === paymentMethod.currency
          ),
        };
      });
      setBasePaymentMethods(
        paymentMethods.map((paymentMethod) => {
          return {
            ...paymentMethod,
            currency: baseCurrencies.find(
              (currency) => currency.id === paymentMethod.currency
            ),
          };
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getPaymentMethods();
  }, [baseCurrencies]);

  useEffect(() => {
    setPaymentMethodsOfCurrency(currentCurrency);
  }, [currentCurrency, basePaymentMethods]);

  useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      if (value.payment_method === -1) {
        setShowCreateCash(true);
      } else {
        setShowCreateCash(false);
      }
    });
    return () => subscription.unsubscribe();
  }, [methods.watch]);
  return (
    <>
      <div className="flex flex-row items-center mb-4">
        <div className="w-6 h-6">
          <img src={PaymentMethodsIcon} alt="" />
        </div>
        <div className="ml-3">Payment Method</div>
      </div>
      <div>
        <SelectInput
          options={paymentMethods}
          name="payment_method"
          placeholder="Select Payment Method"
          serverErrors={error}
        />
      </div>
      {showCreateCash && (
        <ErrorMessage>
          <div className="flex flex-row items-start">
            <div className="mr-2">
              <img src={ErrorIcon} alt="" />
            </div>
            <div className="text-sm font-medium text-black font-roboto leading-5">
              You don't have cashbox in {currentCurrency?.name}, create a cash
              box or choose another payment method
              <div className="mt-1">
                <a href="#" onClick={createNewCashBox}>
                  Create a cash box
                </a>
              </div>
            </div>
          </div>
        </ErrorMessage>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    baseCurrencies: state.Currencies.baseCurrencies,
    basePaymentMethods: state.PaymentMethods.basePaymentMethods,
    currentCompany: state.Company.currentCompany,
  };
};
export default connect(mapStateToProps, { setBasePaymentMethods })(
  PaymentMethod
);
