import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { closeFileViewer } from "../../store/Layout/FileViewer/actions";
import { getFileType } from "../../utils";
import CancelIcon from "../../assets/imgs/icons/cancelWhite.svg";
import ImageViewer from "../ImageViewer";
import DocViewer from "../DocViewer";
import SheetViewer from "../SheetViewer";
import PdfViewer from "../PdfViewer";

const FileViewer = ({ file, isOpen, closeFileViewer }) => {
  const handleViewFile = () => {
    const type = getFileType(file?.name);
    if (!file.link) {
      const url = window.URL.createObjectURL(file, {});
      file.link = url;
    }
    if (type === "image") {
      return <ImageViewer image={file?.fileOnServer?.file} />;
    } else if (type === "word") {
      return <DocViewer word={file?.fileOnServer?.file} />;
    } else if (type === "excel") {
      return <SheetViewer sheet={file?.fileOnServer?.file} />;
    } else if (type === "pdf") {
      return <PdfViewer pdf={file?.fileOnServer?.file} />;
    }
  };

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      closeFileViewer();
    }
  }, []);
  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);
  return (
    <div>
      {isOpen && (
        <div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center">
          <div className="flex flex-col p-1 bg-black bg-opacity-50 h-693 max-w-940 min-w-630">
            <div className="flex flex-row-reverse">
              <div
                className="text-white cursor-pointer"
                onClick={() => closeFileViewer()}
              >
                <img src={CancelIcon} alt="" />
              </div>
            </div>
            <div className="w-full h-full p-4">{handleViewFile()}</div>
          </div>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    isOpen: state.FileViewer.isOpen,
    file: state.FileViewer.file,
  };
};
export default connect(mapStateToProps, { closeFileViewer })(FileViewer);
