import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import ErrorMessage from "../../ErrorMessage";
import visibility from "../../../assets/imgs/icons/visibility.svg";
import nonVisibility from "../../../assets/imgs/icons/nonVisibility.svg";
import ErrorInput from "../Error";
import _ from "lodash";
const PasswordInput = ({ name, serverErrors, ...props }) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [masked, setMasked] = useState("");
  const [timer, setTimer] = useState("");
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleOnChange = (e) => {
    const passwordValue = e.target.value;
    const hiddenPassValue = password;

    let showLength = 1;

    let offset = passwordValue.length - hiddenPassValue.length;

    if (offset > 0) {
      setPassword(
        hiddenPassValue +
          passwordValue.substring(
            hiddenPassValue.length,
            hiddenPassValue.length + offset
          )
      );
    } else if (offset < 0) {
      setPassword(
        hiddenPassValue.substring(0, hiddenPassValue.length + offset)
      );
    }
    // Change the visible string
    // if (passwordValue.length > showLength) {
    setMasked(
      passwordValue
        .substring(0, passwordValue.length - showLength)
        .replace(/./g, "•") +
        passwordValue.substring(
          passwordValue.length - showLength,
          passwordValue.length
        )
    );

    // Set the timer
    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        setMasked(passwordValue.replace(/./g, "•"));
      }, 2000)
    );
  };
  useEffect(() => {
    setValue(name, password, { shouldValidate: errors.[name] });
  }, [password]);
  return (
    <div className="w-full">
      <div className="relative">
        <input
          type="text"
          className={`w-full mb-1 placeholder:text-inputGrayColor placeholder:text-sm ${
            _.get(errors, name)
              ? "text-errorColor border-errorColor focus:text-errorColor focus:border-errorColor focus:bg-white placeholder:text-errorColor"
              : ""
          }`}
          onChange={handleOnChange}
          value={showPassword ? password : masked}
          {...props}
        />
        <input
          type={"password"}
          className={`w-full mb-1 placeholder:text-inputGrayColor placeholder:text-sm ${
            _.get(errors, name)
              ? "text-errorColor border-errorColor focus:text-errorColor focus:border-errorColor focus:bg-white placeholder:text-errorColor"
              : ""
          }`}
          {...register(name)}
          hidden={true}
          disabled={true}
          {...props}
        />
        <div class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
          <img
            src={showPassword ? visibility : nonVisibility}
            onClick={toggleShowPassword}
          />
        </div>
      </div>
      
      <ErrorInput name={name} errors={errors} serverErrors={serverErrors} />

    </div>
  );
};

export default PasswordInput;
