import React, { useState, useEffect } from "react";
import useModal from "../../Modal/useModal";
import createNewCategory from "../../Modal/createNewCategory";
import successUploadDocument from "../../Modal/successUploadDocument";
import UploadDocumentService from "../../../services/UploadDocument";
import CreatableSelectInput from "../../Inputs/CreatableSelect";
import CategoryIcon from "../../../assets/imgs/icons/category.svg";

const Category = ({ error }) => {
  const [categories, setCategories] = useState([]);
  const callPromptModalCategory = useModal(createNewCategory);
  const handleCreateNewCategory = async () => {
    try {
      const res = await callPromptModalCategory({});
      setCategories(
        res.map((category) => {
          return { value: category.id, label: category.name };
        })
      );
    } catch (e) {
      console.error(e);
    }
  };
  const getCategories = async () => {
    try {
      const categories = await UploadDocumentService.getCategories();
      setCategories(
        categories.map((category) => {
          return { value: category.id, label: category.name };
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);
  return (
    <>
      <div className="flex flex-row items-center mb-4">
        <div className="w-6 h-6">
          <img src={CategoryIcon} alt="" />
        </div>
        <div className="ml-3">Category</div>
      </div>
      <div>
        <CreatableSelectInput
          options={categories}
          name="client_category"
          placeholder="Select Category"
          title="Create a new category"
          onCreate={handleCreateNewCategory}
          serverErrors={error}
        />
      </div>
    </>
  );
};

export default Category;
