const Circle = ({ color = "#c4c4c4" }) => {
  return (
    <div
      className={`rounded-full w-full h-full`}
      style={{ backgroundColor: color }}
    ></div>
  );
};

export default Circle;
