import getHttpClient from "../api";
const client = getHttpClient();

export default {
  getEmployeesOfCompany: async (companyId) => {
    try {
      const response = await client.get(
        `/user-management/employee/${companyId}`,
        {}
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  addNewEmployee: async (
    companyId,
    currency,
    basic_salary,
    allowance,
    joining_date,
    allow_access,
    employee_type,
    email,
    mobile,
    first_name,
    last_name
  ) => {
    try {
      let data = null;
      if (employee_type === "temporary") {
        data = {
          allow_access: allow_access || false,
          employee_type: employee_type || "permanent",
          user: {
            email: email || null,
            mobile: mobile || null,
            first_name: first_name || null,
            last_name: last_name || null,
          },
        };
      } else {
        data = {
          currency: currency || null,
          basic_salary: basic_salary || 0.0,
          allowance: allowance || 0.0,
          joining_date: joining_date || null,
          allow_access: allow_access || false,
          employee_type: employee_type || "permanent",
          user: {
            email: email || null,
            mobile: mobile || null,
            first_name: first_name || null,
            last_name: last_name || null,
          },
        };
      }
      const response = await client.post(
        `/user-management/employee/${companyId}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
