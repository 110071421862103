import {
  NEXT_STEP,
  PREV_STEP,
  SET_CONTACT,
  SET_CONTACTS,
  SET_STEP,
  SET_TOKEN,
} from "./type";

export const initialState = {
  step: 0,
  token: null,
  contacts: [],
  resetPasswordContact: null,
  resetPasswordType: null,
};

const resetPassword = (state = initialState, action) => {
  switch (action.type) {
    case NEXT_STEP:
      state = {
        ...state,
        step: state.step + 1,
      };
      break;
    case PREV_STEP:
      state = {
        ...state,
        step: state.step - 1,
      };
      break;
    case SET_CONTACT:
      state = {
        ...state,
        resetPasswordContact: action.contact,
        resetPasswordType: action.contactType,
      };
      break;

    case SET_CONTACTS:
      state = {
        ...state,
        contacts: action.contacts,
      };
      break;

    case SET_TOKEN:
      state = {
        ...state,
        token: action.token,
      };
      break;

    case SET_STEP:
      state = {
        ...state,
        step: action.step,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default resetPassword;
