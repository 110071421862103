const SolidButton = ({ children, className, ...props }) => {
  return (
    <button
      className={`w-full p-4 bg-arrowTop rounded text-sm font-bold text-gray-50  disabled:opacity-50 ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default SolidButton;
