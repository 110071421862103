import React, { useState, useEffect, useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { connect } from "react-redux";
import _ from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import SolidButton from "../../Buttons/SolidButton";
import AddWhiteIcon from "../../../assets/imgs/icons/addWhite.svg";
import useModal from "../../Modal/useModal";
import CreateNewEmployee from "../../Modal/CreateNewEmployee";
import AddIcon from "../../../assets/imgs/icons/add.svg";
import SelectFromList from "../../Modal/selectFromList";
import EmployeeService from "../../../services/Employee";
import SelectFromEmployeeList from "../../Modal/SelectFromEmployeeList";
import DateInput from "../../Inputs/Date";
import UploadDocumentService from "../../../services/UploadDocument";
import PaymentMethod from "../CreatePayment/paymentMethod";
import MonthPicker from "../../Inputs/MonthPicker";

const schema = {};
const CreateSalaries = ({ currentCompany }) => {
  const callPromptModalCreateNewEmployee = useModal(CreateNewEmployee);
  const callPromptModalSelectEmployee = useModal(SelectFromEmployeeList);
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      document_date: new Date(),
      type: "payments",
      description: "",
      paid_amount: "",
      outstandings: [],
      currency: null,
      partner: null,
      paymentMethod: null,
    },
  });
  const [error, setError] = useState("");
  const [minDate, setMinDate] = useState(null);
  const [currentCurrency, setCurrentCurrency] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [employeesOfCompany, setEmployeesOfCompany] = useState([]);

  const handleCreateNewEmployee = async () => {
    try {
      const res = await callPromptModalCreateNewEmployee({});
      setEmployeesOfCompany(res);
    } catch (e) {
      console.error(e);
    }
  };
  const handleAddEmployee = async () => {
    try {
      const res = await callPromptModalSelectEmployee({
        title: "Employees",
        employees: employeesOfCompany,
        selectedList: employees.map((employee) => employee.id),
      });
      setEmployees(res);
    } catch (e) {
      console.error(e);
    }
  };

  const getEmployeesOfCompany = async () => {
    try {
      const employeesOfCompany = await EmployeeService.getEmployeesOfCompany(
        currentCompany?.id
      );
      setEmployeesOfCompany(employeesOfCompany);
    } catch (error) {
      console.error(error);
    }
  };

  const getFiscalYear = async () => {
    try {
      const fiscalYear = await UploadDocumentService.getFiscalYear(
        currentCompany.id
      );
      setMinDate(
        Math.max(
          new Date(fiscalYear?.lock_date),
          new Date(fiscalYear?.date_from)
        )
      );
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getEmployeesOfCompany();
    getFiscalYear();
  }, []);

  const onSubmit = async (data) => {};
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div>
          <div className="flex flex-row justify-between mt-2">
            <div></div>
            <div>
              <SolidButton onClick={handleCreateNewEmployee}>
                <div className="flex flex-row">
                  <div className="mr-4">
                    <img src={AddWhiteIcon} alt="" />
                  </div>
                  <div>Create New Employee</div>
                </div>
              </SolidButton>
            </div>
          </div>
          <div className="w-full flex flex-row justify-between mt-4">
            <div className="mb-6 w-1/3">
              <div className="flex flex-row items-center mb-4">
                <div className="ml-3">Payslip Month</div>
              </div>
              <div>
                <MonthPicker
                  name="document_date"
                  maxDate={new Date()}
                  minDate={minDate}
                  serverErrors={error}
                />
              </div>
            </div>
            <div className="mb-6 w-1/3 px-4">
              <div className="flex flex-row items-center mb-4">
                <div className="ml-3">Payment Date</div>
              </div>
              <div>
                <DateInput
                  name="document_date"
                  maxDate={new Date()}
                  minDate={minDate}
                  serverErrors={error}
                />
              </div>
            </div>
            <div className="mb-6 w-1/3">
              <PaymentMethod currentCurrency={currentCurrency} error={error} />
            </div>
          </div>
          <div className="mt-4">
            <table class="table-auto text-sm whitespace-nowrap">
              <thead className="bg-bgGray">
                <tr>
                  <th className="p-4">Employee</th>
                  <th className="p-4">Currency</th>
                  <th className="p-4">Basic Salary</th>
                  <th className="p-4">Other Allowances</th>
                  <th className="p-4">Additional</th>
                  <th className="p-4">Deduction</th>
                  <th className="p-4">Reason</th>
                  <th className="p-4">Total</th>
                  <th className="p-4">Payment Date</th>
                  <th className="p-4">Payment Method</th>
                </tr>
              </thead>
              <tbody>
                {employees.map((employee) => {
                  return (
                    <tr className="border border-gray-100">
                      <td className="hidden">{employee.id}</td>
                      <td>{employee.name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="flex flex-row justify-center">
              <div className="text-sm font-bold cursor-pointer font-roboto text-arrowTop">
                <div
                  className="flex flex-row items-center justify-center"
                  onClick={handleAddEmployee}
                >
                  <img src={AddIcon} />
                  <div>Add employee</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const mapStateToProps = (state) => {
  return { currentCompany: state.Company.currentCompany };
};
export default connect(mapStateToProps, {})(CreateSalaries);
