import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker";
import arrowRightIcon from "../../assets/imgs/icons/arrow_r.svg";
import arrowLeftIcon from "../../assets/imgs/icons/arrow_l.svg";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
const PdfViewer = ({ pdf }) => {
  const [url, setUrl] = useState(pdf);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const nextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };
  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <div className="w-full max-h-552">
      <Document
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        className="max-h-552 flex justify-center items-center"
      >
        <Page pageNumber={pageNumber} height="552" />
      </Document>
      <div className="flex items-center py-8 px-28 w-full ">
        <div className="flex flex-row justify-between w-full text-white">
          <div className="cursor-pointer" onClick={prevPage}>
            <img src={arrowLeftIcon} alt="" />
          </div>
          <div className="text-sm">
            {pageNumber} of {numPages}
          </div>
          <div className="cursor-pointer" onClick={nextPage}>
            <img src={arrowRightIcon} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PdfViewer;
