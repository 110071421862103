import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { trackPromise } from "react-promise-tracker";

import Circle from "../../Circle";
import SelectInput from "../../Inputs/Select";
import MonetaryInput from "../../Inputs/Monetary";
import PaymentMethodsIcon from "../../../assets/imgs/icons/paymentMethods.svg";
import DescriptionIcon from "../../../assets/imgs/icons/description.svg";
import CostCenterIcon from "../../../assets/imgs/icons/money.svg";
import CategoryIcon from "../../../assets/imgs/icons/category.svg";
import AmountIcon from "../../../assets/imgs/icons/amount.svg";
import BankIcon from "../../../assets/imgs/icons/bank.svg";
import CashIcon from "../../../assets/imgs/icons/moneyCash.svg";
import TextareaInput from "../../Inputs/TextArea";
import UploadDocumentService from "../../../services/UploadDocument";
import { getCookie } from "../../../utils";
import createNewCategory from "../../Modal/createNewCategory";
import useModal from "../../Modal/useModal";
import CreatableSelectInput from "../../Inputs/CreatableSelect";
import SubmitButton from "../../Buttons/Submit";
import RadioButtonInput from "../../Inputs/RadioButton";
import AssignToSomeone from "../../AssignToSomeone";
import { ToastifyError, ToastifySuccess } from "../../../toastify";
import DropDownContent from "../../DropdownContent";
import UploadFiles from "../../UploadFiles";
import successUploadDocument from "../../Modal/successUploadDocument";
import { useBlocker } from "../../../hooks/usePrompt";
import ConfirmExit from "../../Modal/confirmExit";

const PATMENT_STATUSES = [
  { key: "fully_paid", value: "Fully Paid", color: "#48BB78" },
  { key: "partially_paid", value: "Partially Paid", color: "#F5841F" },
  { key: "not_paid", value: "Not Paid", color: "#FF3355" },
];

const schema = yup
  .object({
    payment_status: yup.string().nullable().required("This field is required"),
    description: yup.string(),
    payment_method: yup.number().when("payment_status", {
      is: (payment_status) => {
        return payment_status !== "not_paid";
      },
      then: yup.number().nullable().required("This field is required"),
    }),
    amount: yup
      .number()
      .transform((val, value) => {
        return value === "" ? 0.0 : val;
      })
      .when("payment_status", {
        is: (payment_status) => {
          return payment_status === "partially_paid";
        },
        then: yup
          .number()
          .required("This field is required")
          .typeError("Amount must be a number")
          .test("format", "Invalid Number", (value) => {
            return /^-*[0-9,]+([.][0-9]{0,2})?$/.test(value || 0.0);
          })
          .max("100000000")
          .test("Is Zero?", "Amount can’t be zero", (value) => value !== 0)
          .positive("Amount can’t be a negative number"),
      }),
    description: yup.string().max(160),
    cost_center: yup.number(),
    client_category: yup.number(),
  })
  .required();
const UploadDocument = ({ currentCompany }) => {
  const navigate = useNavigate();
  const callPromptModal = useModal(createNewCategory);
  const callPromptSuccessUploadDocument = useModal(successUploadDocument);
  const user = JSON.parse(getCookie("user"));
  const [error, setError] = useState("");
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [constCenters, setConstCenters] = useState([]);
  const [categories, setCategories] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [files, setFiles] = React.useState([]);
  const [isUploading, setIsUploading] = React.useState(false);
  const [selectedAssignee, setSelectAssignee] = React.useState([]);
  const [isBlocking, setIsBlocking] = useState(false);
  const callPromptModalConfrimExit = useModal(ConfirmExit);
  const handleConfirmExit = async (nextLocation) => {
    try {
      setIsBlocking(false);
      const res = await callPromptModalConfrimExit({
        title: "Save changes before closing",
        message:
          "All changes will be lost if you close, are you sure you want to discard your changes?",
      });
      if (res) {
        navigate(nextLocation.location.pathname);
      } else {
        setIsBlocking(true);
      }
    } catch (e) {
      setIsBlocking(true);
      console.error(e);
    }
  };
  useBlocker(handleConfirmExit, isBlocking);
  const handleCreateNewCategory = async () => {
    try {
      const res = await callPromptModal({
        title: "Deleting items",
        message: `no items to delete, please select a one at least`,
      });
      setCategories(
        res.map((category) => {
          return { value: category.id, label: category.name };
        })
      );
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    getCategories();
    getPaymentMethods();
    getCostCenters();
    getAssignee();
  }, []);

  useEffect(() => {
    setIsBlocking(methods.formState.isDirty);
  }, [methods.formState.isDirty]);

  useEffect(() => {
    setIsBlocking(files.length > 0 || selectedAssignee.length > 0);
  }, [files, selectedAssignee]);
  useEffect(() => {
    if (paymentMethods.length === 1) {
      methods.setValue("payment_method", paymentMethods[0].value);
    }
  }, [paymentMethods]);

  const getPaymentMethods = async () => {
    try {
      const paymentMethods = await UploadDocumentService.getPaymentMethods(
        currentCompany.id
      );
      setPaymentMethods(
        paymentMethods.map((paymentMethod) => {
          return {
            value: paymentMethod.id,
            label: paymentMethod.name,
            icon: paymentMethod.type === "cash" ? CashIcon : BankIcon,
          };
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
  const getCostCenters = async () => {
    try {
      const constCenters = await UploadDocumentService.getCostCenters(
        currentCompany.id
      );
      setConstCenters(
        constCenters.map((constCenter) => {
          return { value: constCenter.id, label: constCenter.name };
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const getAssignee = async () => {
    try {
      const assignees = await UploadDocumentService.getAssigneeOfCompany(
        currentCompany.id
      );
      setAssignees(
        assignees.map((assignee) => {
          return { value: assignee.id, label: assignee.name };
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
  const getCategories = async () => {
    try {
      const categories = await UploadDocumentService.getCategories();
      setCategories(
        categories.map((category) => {
          return { value: category.id, label: category.name };
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
  const onSubmit = async (data) => {
    try {
      const paymentStatus = data?.payment_status;
      const paymentMethod =
        paymentStatus === "not_paid" ? null : data?.payment_method;
      const costCenter = data?.cost_center;
      const clientCategory = data?.client_category;
      const description = data?.description;
      const amount = paymentStatus === "not_paid" ? null : data?.amount;
      const attachmentsDocument = files
        .filter((attachment) => attachment?.fileOnServer?.id)
        .map((attachment) => attachment?.fileOnServer?.id);
      if (!attachmentsDocument.length) {
        ToastifyError(
          "At least one attachment must be added to be able to proceed."
        );
        return;
      }
      const submit = async () => {
        const document = await UploadDocumentService.submitDocument(
          currentCompany.id,
          attachmentsDocument,
          paymentStatus,
          paymentMethod,
          clientCategory,
          costCenter,
          amount,
          description,
          selectedAssignee
        );
        return document;
      };
      await trackPromise(submit());
      await callPromptSuccessUploadDocument({
        time: 5000,
        message: "The Request was processed successfully",
        title: "Successful!",
      });
      window.location.reload();
    } catch (error) {
      console.error(error);
      if (error?.detail) ToastifyError(error?.detail);
      setError(error);
    }
  };
  const getPaymentStatus = (paymentStatusKey) => {
    return PATMENT_STATUSES.find(
      (paymentStatus) => paymentStatus.key === paymentStatusKey
    );
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex flex-row mt-10 mb-28">
          <div className="flex justify-center w-1/2">
            <div className="w-full">
              <div className="mb-6">
                <div className="flex flex-row items-center mb-4">
                  <div className="w-6 h-6">
                    <Circle
                      color={
                        getPaymentStatus(methods.watch("payment_status"))?.color
                      }
                    />
                  </div>
                  <div className="ml-3">Payment Status</div>
                </div>
                <RadioButtonInput
                  name="payment_status"
                  options={PATMENT_STATUSES}
                  serverErrors={error}
                />
              </div>
              {getPaymentStatus(methods.watch("payment_status"))?.key ===
                "partially_paid" && (
                <div className="mb-6">
                  <div className="flex flex-row items-center mb-4">
                    <div className="w-6 h-6">
                      <img src={AmountIcon} alt="" />
                    </div>
                    <div className="ml-3">Amount</div>
                  </div>
                  <div>
                    <MonetaryInput name="amount" serverErrors={error} />
                  </div>
                </div>
              )}
              {getPaymentStatus(methods.watch("payment_status"))?.key !==
                "not_paid" && (
                <div className="mb-6">
                  <div className="flex flex-row items-center mb-4">
                    <div className="w-6 h-6">
                      <img src={PaymentMethodsIcon} alt="" />
                    </div>
                    <div className="ml-3">Payment Method</div>
                  </div>
                  <div>
                    <SelectInput
                      options={paymentMethods}
                      name="payment_method"
                      placeholder="Select Payment Method"
                      serverErrors={error}
                      defaultValue={paymentMethods[0]?.value}
                    />
                  </div>
                </div>
              )}
              <div className="mb-6">
                <div className="flex flex-row items-center mb-4">
                  <div className="w-6 h-6">
                    <img src={DescriptionIcon} alt="" />
                  </div>
                  <div className="ml-3">Description</div>
                </div>
                <div className="w-full h-full">
                  <TextareaInput
                    rows="4"
                    id="description"
                    name="description"
                    placeholder="Add Description"
                    serverErrors={error}
                  />
                </div>
              </div>
              <div>
                <DropDownContent title="Additional info" className="pb-32">
                  {constCenters.length > 0 && (
                    <div className="mb-6">
                      <div className="flex flex-row items-center mb-4">
                        <div className="w-6 h-6">
                          <img src={CostCenterIcon} alt="" />
                        </div>
                        <div className="ml-3">Cost Center</div>
                      </div>
                      <div>
                        <SelectInput
                          options={constCenters}
                          name="cost_center"
                          placeholder="Select Cost Center"
                          serverErrors={error}
                        />
                      </div>
                    </div>
                  )}
                  <div className="">
                    <div className="flex flex-row items-center mb-4">
                      <div className="w-6 h-6">
                        <img src={CategoryIcon} alt="" />
                      </div>
                      <div className="ml-3">Category</div>
                    </div>
                    <div>
                      <CreatableSelectInput
                        options={categories}
                        name="client_category"
                        placeholder="Select Category"
                        title="Create a new category"
                        onCreate={handleCreateNewCategory}
                        serverErrors={error}
                      />
                    </div>
                  </div>
                </DropDownContent>
              </div>
            </div>
          </div>
          <div className="flex justify-center w-1/2 ml-20">
            <UploadFiles
              files={files}
              setFiles={setFiles}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              error={error}
              title="Upload File(s)"
            />
          </div>
        </div>
        <div className="fixed bottom-0 left-0 flex justify-center w-screen h-20 bg-white shadow-footerShadow">
          <div className="w-full h-full max-w-screen-lg">
            <div className="flex flex-row items-center justify-between h-full align-middle">
              <AssignToSomeone
                options={assignees}
                onChange={(selectedAssignee) => {
                  setSelectAssignee(selectedAssignee);
                }}
              />
              <div className="flex flex-row items-center justify-center">
                <div className="p-4 font-bold font-roboto text-arrowTop"></div>
                <div className="w-40">
                  {getPaymentStatus(methods.watch("payment_status")) && (
                    <SubmitButton
                      disabled={methods.formState.isSubmitting || isUploading}
                    >
                      {getPaymentStatus(methods.watch("payment_status"))?.value}
                    </SubmitButton>
                  )}
                  {!getPaymentStatus(methods.watch("payment_status")) && (
                    <div className="bg-gray-300 flex justify-center items-center p-4">
                      {" "}
                      --{" "}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const mapStateToProps = (state) => {
  return { currentCompany: state.Company.currentCompany };
};
export default connect(mapStateToProps, {})(UploadDocument);
