import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import EmployeeService from "../../services/Employee";
import TextInput from "../Inputs/Text";
import validateTextInput from "../Inputs/Text/validation_with_numbers";
import validateAmountInput from "../Inputs/Amount/validation";
import SubmitButton from "../Buttons/Submit";
import ErrorMessage from "../ErrorMessage";
import DateInput from "../Inputs/Date";
import CancelIcon from "../../assets/imgs/icons/cancel.svg";
import validateMobileInput from "../Inputs/Mobile/validation";
import EmailInput from "../Inputs/Email";
import CountryMobileInput from "../Inputs/CountryMobile";
import MonetaryWithCurrencyInput from "../Inputs/MonetaryWithCurrency";
import { formatDate, getCookie } from "../../utils";
import CurrencyService from "../../services/Currency";
import { setBaseCurrencies } from "../../store/currencies/actions";
import MonetaryInput from "../Inputs/Monetary";
import CheckBoxInput from "../Inputs/CheckBox";
import RadioTabInput from "../Inputs/RadioTabs";
import ErrorInput from "../Inputs/Error";

const EMPLOYEE_TYPES = [
  { key: "permanent", value: "Permanent" },
  { key: "temporary", value: "Temporary" },
];
const schema = yup
  .object({
    user: yup.object({
      first_name: yup
        .string()
        .required("This field is required")
        .matches(/^[A-Za-z]+$/i, "Invalid Input")
        .min(3, "Minimum 3 characters")
        .max(20, "max 20 characters"),
      last_name: yup
        .string()
        .required("This field is required")
        .matches(/^[A-Za-z]+$/i, "Invalid Input")
        .min(3, "Minimum 3 characters")
        .max(20, "max 20 characters"),
      email: yup
        .string()
        .email("Invalid email")
        .when(["mobile"], {
          is: (mobile, allow_access) => {
            return allow_access && !mobile;
          },
          then: yup.bool().required(),
        }),
    }),

    emailOrMobile: yup
      .bool()
      .when(["user.email", "user.mobile", "allow_access"], {
        is: (email, mobile, allow_access) => {
          return allow_access && !email && !mobile;
        },
        then: yup
          .bool()
          .required(
            `Add a UAE Mobile Number or add an email to give the employee access to Theaccounter`
          ),
      }),
    basic_salary: yup.string().when("employee_type", {
      is: "permanent",
      then: (schema) => validateAmountInput.required("This field is required"),
    }),
    allowance: yup.string().when("employee_type", {
      is: "permanent",
      then: (schema) => {
        return validateAmountInput.required("This field is required");
      },
    }),
    joining_date: yup.string().when("employee_type", {
      is: "permanent",
      then: (schema) => schema.required("This field is required"),
    }),
  })
  .required();
const CreateNewEmployee = ({
  baseCurrencies,
  setBaseCurrencies,
  currentCompany,
  onResolve,
  onReject,
}) => {
  const user = JSON.parse(getCookie("user"));
  const [currencies, setCurrencies] = useState([]);
  const [total, setTotal] = useState(0.0);
  const [currentCurrency, setCurrentCurrency] = useState(null);
  const [error, setError] = useState();
  const backRef = useRef(null);
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const employee_type = methods.watch("employee_type");
  const onSubmit = async (data) => {
    try {
      const companyId = currentCompany.id;
      const currency = currentCurrency.id;
      const first_name = data.user.first_name;
      const last_name = data.user.last_name;
      const email = data.user.email;
      const mobile = data.user.mobile;
      const employee_type = data.employee_type;
      const basic_salary = data.basic_salary;
      const allowance = data.allowance;
      const joining_date = formatDate(data.joining_date || null);
      const allow_access = data.allow_access;
      const employee = await EmployeeService.addNewEmployee(
        companyId,
        currency,
        basic_salary,
        allowance,
        joining_date,
        allow_access,
        employee_type,
        email,
        mobile,
        first_name,
        last_name
      );
      onResolve(employee);
    } catch (error) {
      console.log(error);
      const errorWrapper = {
        ...error,
        user: { ...error?.user, mobile: error?.user?.mobile || error?.mobile },
      };
      setError(errorWrapper);
    }
  };

  const onChangeCurrency = (value) => {
    methods.setValue("currency", value);
    setCurrentCurrency(
      baseCurrencies.find((currency) => currency.id === value)
    );
  };
  const getCurrencies = async () => {
    try {
      const currencies = await CurrencyService.getCurrencies(currentCompany.id);
      setBaseCurrencies(currencies);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      setTotal(
        parseFloat(value.basic_salary) + parseFloat(value.allowance) || 0.0
      );
    });
    return () => subscription.unsubscribe();
  }, [methods.watch]);
  useEffect(() => {
    getCurrencies();
    methods.setValue("employee_type", "permanent");
  }, []);
  useEffect(() => {
    setCurrencies(
      baseCurrencies.map((currency) => {
        return {
          value: currency.id,
          label: `${currency.code} - ${currency.name}`,
          icon: currency.flag,
        };
      })
    );

    methods.setValue(
      "currency",
      baseCurrencies.find((currency) => currency.default)?.id
    );
    setCurrentCurrency(baseCurrencies.find((currency) => currency.default));
  }, [baseCurrencies]);

  useEffect(() => {
    methods.setValue("joining_date", "");
    methods.setValue("basic_salary", "");
    methods.setValue("allowance", "");
  }, [employee_type]);
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} name="createEmployee">
        <div
          ref={backRef}
          isOpen={backRef}
          class="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              class="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start w-full ">
                  <div class="w-full">
                    <div className="flex flex-row justify-between">
                      <h3
                        class="text-xl leading-7 font-bold text-gray-900 font-poppins"
                        id="modal-title"
                      >
                        Create new employee
                      </h3>
                      <div
                        className="cursor-pointer"
                        onClick={() => onReject()}
                      >
                        <img src={CancelIcon} alt="" />
                      </div>
                    </div>

                    {error?.detail && (
                      <ErrorMessage>{error.detail}</ErrorMessage>
                    )}
                    <div>
                      <div>Name</div>
                      <div className="flex w-full mt-2">
                        <div className="w-full mr-2">
                          <TextInput
                            name="user.first_name"
                            serverErrors={error}
                            placeholder="First Name"
                          />
                        </div>
                        <div className="w-full ml-2">
                          <TextInput
                            name="user.last_name"
                            serverErrors={error}
                            placeholder="Last Name"
                          />
                        </div>
                      </div>
                      <div className="flex mt-2">
                        <div className={`flex mt-4 mr-2 flex-col w-full`}>
                          <div>Mobile</div>
                          <CountryMobileInput
                            name="user.mobile"
                            serverErrors={error}
                          />
                        </div>
                        <div className={`flex mt-4 flex-col w-full`}>
                          <div>Email</div>
                          <EmailInput
                            name="user.email"
                            serverErrors={error}
                            placeholder="Email"
                          />
                        </div>
                      </div>

                      <div>
                        {methods?.formState?.errors?.emailOrMobile && (
                          <div className="text-errorColor text-sm">
                            {methods?.formState?.errors?.emailOrMobile?.message}
                          </div>
                        )}
                      </div>
                      <div className="flex flex-row items-center justify-between mt-4">
                        <div>Employee Type</div>
                        <div>
                          <RadioTabInput
                            name="employee_type"
                            options={EMPLOYEE_TYPES}
                            serverErrors={error}
                          />
                        </div>
                      </div>
                      {employee_type === "permanent" && (
                        <>
                          <div className="mt-4">
                            <div>Joining Date</div>
                            <DateInput
                              name="joining_date"
                              maxDate={new Date()}
                              serverErrors={error}
                              placeholderText="Joining Date"
                            />
                          </div>

                          <div className="flex mt-4">
                            <div className={`flex mr-2 flex-col w-full`}>
                              <div>Basic Salary</div>
                              <MonetaryWithCurrencyInput
                                name="basic_salary"
                                currency={currentCurrency}
                                currencies={currencies}
                                onChangeCurrency={onChangeCurrency}
                                serverErrors={error}
                              />
                            </div>
                            <div className={`flex mr-2 flex-col w-full`}>
                              <div>Other Allowances</div>
                              <MonetaryInput
                                name="allowance"
                                serverErrors={error}
                                currency={currentCurrency}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="p-2 mt-1 text-sm">
                      <CheckBoxInput
                        name="allow_access"
                        serverErrors={error}
                        label={`Give this employee access to The Accounter`}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between pt-6 mt-10 border-t-2">
                  <div className="flex flex-col">
                    <div>Total</div>
                    <div className="mt-1 text-xl font-bold font-roboto">
                      {total + " " + currentCurrency?.code}
                    </div>
                  </div>
                  <div className="flex flex-row items-center">
                    <div className="px-8 text-sm font-bold cursor-pointer font-roboto text-grayColor">
                      Cancel
                    </div>
                    <div className="w-36">
                      <SubmitButton>Add Employee</SubmitButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    baseCurrencies: state.Currencies.baseCurrencies,
    currentCompany: state.Company.currentCompany,
  };
};
export default connect(mapStateToProps, { setBaseCurrencies })(
  CreateNewEmployee
);
