import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import CodeVerification from "../../components/Forms/CodeVerification";
import Logo from "../../assets/imgs/logoTheaccounter.png";
const VerifyAccount = ({ contact, type }) => {
  let navigate = useNavigate();
  const handleStep = () => {
    return (
      <CodeVerification
        contact={contact}
        type={type}
        validate_contact={false}
        goNext={() => {
          navigate("/login");
        }}
      />
    );
  };
  return (
    <section className="flex flex-col mx-16 pt-12 h-screen justify-between	">
      <div className="flex flex-row">
        <img src={Logo} alt="" />
      </div>
      <div className="flex flex-col items-center justify-center mt-6 mb-auto">
        <div className="w-425 flex flex-col p-1">{handleStep()}</div>
      </div>

      <div className="w-full justify-self-end mt-6 mb-1.875">
        <div className="text-center">
          Copyright © 2021 The Accounter - All Rights Reserved.
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.verifyAccount.contact,
    type: state.verifyAccount.type,
  };
};
export default connect(mapStateToProps, {})(VerifyAccount);
