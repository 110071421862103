import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import EmailInput from "../../Inputs/Email";
import MobileInput from "../../Inputs/Mobile";
import PasswordInput from "../../Inputs/Password";
import TextInput from "../../Inputs/Text";
import validateTextInput from "../../Inputs/Text/validation";
import validateMobileInput from "../../Inputs/Mobile/validation";
import SubmitButton from "../../Buttons/Submit";
import Auth from "../../../services/Auth";
import ErrorMessage from "../../ErrorMessage";
import { setCurrentUser } from "../../../store/User/actions";

const schema = yup
  .object({
    first_name: validateTextInput
      .required("This field is required")
      .max(20, "max 20 characters"),
    last_name: validateTextInput
      .required("This field is required")
      .max(20, "max 20 characters"),
    email: yup.string().email("Invalid email"),
    mobile: validateMobileInput,
    password: yup
      .string()
      .required("This field is required")
      .min(8, "at least 8 alphanumeric"),
    confirm_password: yup
      .string()
      .required("This field is required")
      .min(8, "at least 8 alphanumeric")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();

const SignupForm = ({ signUpContact, signUpType, setCurrentUser }) => {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    try {
      const first_name = data.first_name;
      const last_name = data.last_name;
      const email = data?.email;
      const mobile = data?.mobile ? "+971" + data?.mobile : null;
      const password = data?.password;
      const user = await Auth.signup(
        first_name,
        last_name,
        email,
        mobile,
        password
      );
      setCurrentUser(user);
      navigate("/");
    } catch (error) {
      setError(error);
    }
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex flex-col">
          <div className="mt-11">Personal Information</div>
          <div className="flex mt-6">
            <div className="w-50 mr-2">
              <TextInput
                name="first_name"
                serverErrors={error}
                placeholder="First Name"
              />
            </div>
            <div className="w-50 ml-2">
              <TextInput
                name="last_name"
                serverErrors={error}
                placeholder="Last Name"
              />
            </div>
          </div>
          <div
            className={`flex mt-4 ${signUpType === "email" ? "hidden" : ""}`}
          >
            <EmailInput
              name="email"
              serverErrors={error}
              defaultValue={signUpType == "email" ? signUpContact : ""}
              placeholder="Email"
            />
          </div>
          <div
            className={`flex mt-4 ${signUpType === "mobile" ? "hidden" : ""}`}
          >
            <MobileInput
              name="mobile"
              serverErrors={error}
              defaultValue={
                signUpType == "mobile" ? signUpContact.replace("+971", "") : ""
              }
            />
          </div>

          <div className="flex mt-4">
            <PasswordInput
              name="password"
              serverErrors={error}
              placeholder="Password"
            />
          </div>

          <div className="flex mt-4">
            <PasswordInput
              name="confirm_password"
              serverErrors={error}
              placeholder="Confirm Password"
            />
          </div>
          {error?.detail && <ErrorMessage>{error.detail}</ErrorMessage>}
          {error?.[signUpType] && (
            <ErrorMessage>{error?.[signUpType]}</ErrorMessage>
          )}
          <div className="mt-8">
            <SubmitButton disabled={methods.formState.isSubmitting}>
              Sign Up
            </SubmitButton>
          </div>
          <div className="mt-8">
            <div className="text-center text-sm leading-5	font-roboto">
              By signing up, you are agreeing to our{" "}
              <a href="https://theaccounter.com">Terms & Conditions</a> and
              <a href="https://theaccounter.com"> Privacy Policy</a>.
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    signUpContact: state.signup.signUpContact,
    signUpType: state.signup.signUpType,
  };
};
export default connect(mapStateToProps, {
  setCurrentUser,
})(SignupForm);
