import { useFormContext } from "react-hook-form";
import ErrorInput from "../Error";
import _ from "lodash";

const AmountInput = ({ name, currency, serverErrors, ...props }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <div className="w-full relative">
      <div className="w-full">
        <input
          type="text"
          className={` w-full mb-1${
            _.get(errors, name)
              ? "text-errorColor border-errorColor focus:text-errorColor focus:border-errorColor focus:bg-white placeholder:text-errorColor"
              : ""
          }`}
          {...register(name, { defaultValue: 0.0 })}
          {...props}
        />
      </div>

      <ErrorInput name={name} errors={errors} serverErrors={serverErrors} />
    </div>
  );
};

export default AmountInput;
