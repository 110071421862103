import { SET_CONTACT } from "./actionsType";

export const initialState = {
  contact: null,
  type: null,
};

const verifyAccount = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTACT:
      state = {
        ...state,
        contact: action.contact,
        type: action.contactType,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default verifyAccount;
