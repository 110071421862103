import { SET_ALLOWED_COMPANIES, SET_CURRENT_COMPANY } from "./type";

export const setCurrentCompany = (company) => {
  localStorage.setItem("company", company.id);
  return {
    type: SET_CURRENT_COMPANY,
    company: company,
  };
};

export const setAllowedCompany = (companies) => {
  return {
    type: SET_ALLOWED_COMPANIES,
    companies: companies,
  };
};
