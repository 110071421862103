const CircleProgressBar = ({ step }) => {
  return (
    <div className="flex flex-row items-center mb-12">
      <span className={`h-3 w-3 rounded-full bg-arrowTop mr-1`}></span>
      <span className="w-10 h-1 border-b-2 border-solid border-gray mr-1"></span>
      <span
        className={`h-3 w-3 rounded-full ${
          step >= 1 ? "bg-arrowTop" : "bg-white border border-solid border-gray"
        } mr-1`}
      ></span>
      <span className="w-10 h-1 border-b-2 border-solid border-gray mr-1"></span>
      <span
        className={`h-3 w-3 rounded-full ${
          step >= 2 ? "bg-arrowTop" : "bg-white border border-solid border-gray"
        } mr-1`}
      ></span>
    </div>
  );
};

export default CircleProgressBar;
