import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import ResetPasswordForm from "../../components/Forms/ResetPassword";
import Logo from "../../assets/imgs/logoTheaccounter.png";
import { setToken } from "../../store/resetPassword/actions";
const AcceptInvitation = ({ setToken }) => {
  const { token } = useParams();
  useEffect(() => {
    setToken(token);
  }, [token]);
  const handleStep = () => {
    return <ResetPasswordForm type="invitation" />;
  };
  return (
    <section className="flex flex-col mx-16 pt-12 h-screen justify-between	">
      <div className="flex flex-row">
        <img src={Logo} alt="" />
      </div>
      <div className="flex flex-col items-center justify-center mt-6 mb-auto">
        <div className="w-425 flex flex-col p-1">{handleStep()}</div>
      </div>

      <div className="w-full justify-self-end mt-6 mb-1.875">
        <div className="text-center">
          Copyright © 2021 The Accounter - All Rights Reserved.
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, { setToken })(AcceptInvitation);
