import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";

import SubmitButton from "../../Buttons/Submit";
import ErrorMessage from "../../ErrorMessage";
import mailIcon from "../../../assets/imgs/icons/mail.svg";
import phoneIcon from "../../../assets/imgs/icons/phone.svg";
import ResetPasswordService from "../../../services/ResetPassword";
import {
  goNext,
  setResetPasswordContact,
} from "../../../store/resetPassword/actions";
import RadioInput from "../../Inputs/Radio";
import { formatContactDisplay, getToastSuccessProps } from "../../../utils";

const schema = yup.object({}).required();
const ContactToResetPassword = ({
  goNext,
  setResetPasswordContact,
  contacts,
}) => {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    try {
      const selectedConctacted = contacts.find(
        (contact) => contact.channel === data?.contact
      );
      setResetPasswordContact(
        selectedConctacted?.contact,
        selectedConctacted?.channel
      );
      if (selectedConctacted?.channel === "email") {
        const { email } = await ResetPasswordService.verifyEmail(
          selectedConctacted?.contact
        );
      } else if (selectedConctacted?.channel === "mobile") {
        const { mobile } = await ResetPasswordService.verifyMobile(
          selectedConctacted?.contact
        );
      }
      toast.success(
        `we have sent a verification code to the ${selectedConctacted.channel} provided`,
        { ...getToastSuccessProps() }
      );

      goNext();
    } catch (error) {
      setError(error);
    }
  };
  const handleContacts = () => {
    return contacts.map((contact, index) => {
      return (
        <>
          <div className="p-4">
            <RadioInput
              id={contact.channel}
              name="contact"
              icon={contact.channel === "email" ? mailIcon : phoneIcon}
              value={contact.channel}
              defaultChecked={contact.selected_contact}
            >
              <div>
                <div className="font-Roboto text-sm	font-medium	leading-4	">
                  Send code via {contact.channel}
                </div>
                <div className="mt-2 font-Roboto text-sm	font-normal	leading-4 text-grayColorText">
                  {formatContactDisplay(contact.contact)}
                </div>
              </div>
            </RadioInput>
          </div>
          {index !== contacts.length - 1 && (
            <span class="bg-borderColor h-px flex-grow mx-4"></span>
          )}
        </>
      );
    });
  };
  return (
    <>
      <div className="font-bold	text-2xl leading-9 font-poppins">
        Forgot your password?
      </div>
      <div className="text-grayColor leading-5 mt-1 text-sm font-roboto font-light">
        How do you want to get the code to reset your password?
      </div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex flex-col mt-8">
            <div className="flex flex-col border border-borderColor rounded">
              {handleContacts()}
            </div>
            {error?.detail && <ErrorMessage>{error.detail}</ErrorMessage>}
            <div className="mt-8">
              <SubmitButton disabled={methods.formState.isSubmitting}>
                Continue
              </SubmitButton>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    contacts: state.resetPassword.contacts,
  };
};
export default connect(mapStateToProps, { goNext, setResetPasswordContact })(
  ContactToResetPassword
);
