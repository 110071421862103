import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import spinnerFile from "../../assets/lottiefile/loader.json";
import { Player } from "@lottiefiles/react-lottie-player";

const Spinner = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-gray-500 bg-opacity-75 transition-opacity z-30">
        <div className="bg-white rounded-2xl flex flex-col items-center h-44 p-4 w-44 shadow-toastify">
          <Player
            autoplay={true}
            loop={true}
            controls={false}
            keepLastFrame={true}
            src={spinnerFile}
            style={{ height: "144px", width: "144px" }}
          ></Player>
        </div>
      </div>
    )
  );
};
export default Spinner;
