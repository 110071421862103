import { useTimer } from "react-timer-hook";
const Timer = ({ expiryTimestamp, onExpire }) => {
  const { seconds, minutes } = useTimer({
    expiryTimestamp,
    onExpire: onExpire,
  });

  const getValue = (value) => {
    const leftDigit = value >= 10 ? value.toString()[0] : "0";
    const rightDigit = value >= 10 ? value.toString()[1] : value.toString();
    return leftDigit + rightDigit;
  };
  return (
    <div className="text-3xl">
      <div>
        <span>{getValue(minutes)}</span>:<span>{getValue(seconds)}</span>
      </div>
    </div>
  );
};

export default Timer;
