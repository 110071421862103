import { getCookie } from "../../utils";
import { SET_CURRENT_USER } from "./type";
export const initialState = {
  currentUser: JSON.parse(getCookie("user")),
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      state = {
        ...state,
        currentUser: action.user,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default user;
