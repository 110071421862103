import getHttpClient from "../api";
const client = getHttpClient();

export default {
  createPayment: async (
    companyId,
    paymentMethod,
    clientCategory,
    costCenter,
    paid_amount,
    description,
    partner,
    currency,
    documentDate,
    addTotalCredit,
    addRemainingCredit,
    assignees,
    attachments,
    outstandings
  ) => {
    try {
      const data = {
        payment_method: paymentMethod || null,
        client_category: clientCategory || null,
        cost_center: costCenter || null,
        paid_amount: paid_amount || 0.0,
        description: description || null,
        currency: currency || null,
        partner: partner || null,
        document_date: documentDate || null,
        attachments: attachments || [],
        assignees: assignees || [],
        outstanding: outstandings || [],
        add_total_credit: addTotalCredit || false,
        add_remaining_credit: addRemainingCredit || false,
      };
      const response = await client.post(
        `/dms/payment-document/${companyId}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
