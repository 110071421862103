import Select, { components } from "react-select";
import { useFormContext, Controller } from "react-hook-form";
import ErrorMessage from "../../ErrorMessage";
import Option from "./option";
import SingleValue from "./singleValue";
import customStyles from "./style";
import filterOptions from "./filterOptions";
import ErrorInput from "../Error";
import _ from "lodash";
const SelectInput = ({ name, options, serverErrors, ...props }) => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();
  return (
    <div>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, ref } }) => {
          return (
            <Select
              inputRef={ref}
              options={options}
              styles={customStyles}
              menuPortalTarget={document.body}
              isSearchable={true}
              components={{
                IndicatorSeparator: null,
                Option: Option,
                SingleValue: SingleValue,
                Input: (inputProps) => (
                  <components.Input
                    {...inputProps}
                    className="react-select__input-custom"
                  />
                ),
              }}
              value={options.find((c) => c.value === value) || null}
              onChange={(val) => {
                onChange(val.value);
              }}
              filterOption={filterOptions}
              {...props}
            />
          );
        }}
      />
      <ErrorInput name={name} errors={errors} serverErrors={serverErrors} />
    </div>
  );
};

export default SelectInput;
