import ErrorMessage from "../../ErrorMessage";
import _ from "lodash";

const ErrorInput = ({ errors, serverErrors, name }) => {
  return (
    <>
      {_.get(errors, name) && (
        <span className="text-errorColor">{_.get(errors, name).message}</span>
      )}
      {_.get(serverErrors, name) && (
        <ErrorMessage>{_.get(serverErrors, name)}</ErrorMessage>
      )}
    </>
  );
};

export default ErrorInput;
