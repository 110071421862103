import React, { useState, useEffect } from "react";
import UploadDocumentService from "../../../services/UploadDocument";
import { getCookie } from "../../../utils";
import CostCenterIcon from "../../../assets/imgs/icons/money.svg";
import SelectInput from "../../Inputs/Select";
import { connect } from "react-redux";

const CostCenter = ({ error, currentCompany }) => {
  const [costCenters, setCostCenters] = useState([]);
  const user = JSON.parse(getCookie("user"));
  const getCostCenters = async () => {
    try {
      const constCenters = await UploadDocumentService.getCostCenters(
        currentCompany.id
      );
      setCostCenters(
        constCenters.map((constCenter) => {
          return { value: constCenter.id, label: constCenter.name };
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCostCenters();
  }, []);
  return (
    <>
      {costCenters.length > 0 && (
        <div className="mb-6">
          <div className="flex flex-row items-center mb-4">
            <div className="w-6 h-6">
              <img src={CostCenterIcon} alt="" />
            </div>
            <div className="ml-3">Cost Center</div>
          </div>
          <div>
            <SelectInput
              options={costCenters}
              name="cost_center"
              placeholder="Select Cost Center"
              serverErrors={error}
            />
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return { currentCompany: state.Company.currentCompany };
};
export default connect(mapStateToProps, {})(CostCenter);
