import { Routes, Route } from "react-router-dom";
import {
  publicRoutes,
  authProtectedRoutes,
  RequireAuth,
  RequireNonAuth,
} from "./routes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalProvider from "./components/Modal/provider";

import AppRoute from "./routes";
import "./App.css";
import FileViewer from "./components/FileViewer";
import Spinner from "./components/Spinner";

const contextClass = {
  error:
    "bg-errorColor text-white w-full flex flex-row justify-center items-center text-sm rounded-md font-roboto font-medium",
  successGreen:
    "bg-greenColor text-white w-full flex flex-row justify-center items-center text-sm rounded-md font-roboto font-medium",
  success:
    "text-black bg-white flex flex-row justify-center items-center text-sm w-455 h-28 px-4 shadow-toastify rounded-xl",
  default:
    "text-black bg-white flex flex-row justify-center items-center text-sm w-455 h-28 px-4 shadow-toastify rounded-xl",
};

//load all icons
function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context("./assets/imgs/icons", false, /\.(png|jpe?g|svg)$/)
);
images.map((image) => {
  let img = new Image();
  img.src = image.default;
});
//load all icons

function App() {
  return (
    <div className="App">
      <ModalProvider>
        <Routes>
          {publicRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={
                <RequireNonAuth>
                  <route.component />
                </RequireNonAuth>
              }
            />
          ))}
          {authProtectedRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={
                <RequireAuth>
                  <route.component />
                </RequireAuth>
              }
            >
              {route?.childrens?.map((route, idx) => (
                <Route
                  key={idx}
                  path={route.path}
                  element={
                    <RequireAuth>
                      <route.component />
                    </RequireAuth>
                  }
                />
              ))}
            </Route>
          ))}
        </Routes>
        <ToastContainer
          toastClassName={({ type }) => contextClass[type || "default"]}
        />
        <FileViewer />

        <Spinner />
      </ModalProvider>
    </div>
  );
}

export default App;
