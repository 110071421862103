import { useFormContext } from "react-hook-form";
import ErrorInput from "../Error";
import _ from "lodash";

const TextareaInput = ({ name, serverErrors, ...props }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <div className="w-full">
      <textarea
        rows="5"
        className={`w-full mb-1 rounded placeholder:text-inputGrayColor placeholder:text-sm ${
          _.get(errors, name)
            ? "text-errorColor border-errorColor focus:text-errorColor focus:border-errorColor focus:bg-white placeholder:text-errorColor"
            : ""
        }`}
        {...register(name)}
        {...props}
        style={{ resize: "none" }}
      ></textarea>

      <ErrorInput name={name} errors={errors} serverErrors={serverErrors} />
    </div>
  );
};

export default TextareaInput;
