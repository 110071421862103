import getHttpClient from "../api";
const client = getHttpClient();

const baseUrl = "/base";
export default {
  getCurrencies: async (companyId) => {
    try {
      const response = await client.get(`${baseUrl}/currency/${companyId}`, {});
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
