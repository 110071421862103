import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useFormContext } from "react-hook-form";
import AmountIcon from "../../../assets/imgs/icons/amount.svg";
import CurrencyService from "../../../services/Currency";
import MonetaryWithCurrencyInput from "../../Inputs/MonetaryWithCurrency";
import { formatDate, getCookie } from "../../../utils";
import { setBaseCurrencies } from "../../../store/currencies/actions";

const AmountWithCurrency = ({
  type,
  setCurrentCurrency,
  currentCurrency,
  error,
  baseCurrencies,
  setBaseCurrencies,
  currentCompany,
}) => {
  const user = JSON.parse(getCookie("user"));
  const [currencies, setCurrencies] = useState([]);
  const methods = useFormContext();

  const getCurrencies = async () => {
    try {
      const currencies = await CurrencyService.getCurrencies(currentCompany.id);
      setBaseCurrencies(currencies);
    } catch (error) {
      console.error(error);
    }
  };
  const onChangeCurrency = (value) => {
    methods.setValue("currency", value);
    setCurrentCurrency(
      baseCurrencies.find((currency) => currency.id === value)
    );
  };
  useEffect(() => {
    getCurrencies();
  }, []);

  useEffect(() => {
    setCurrencies(
      baseCurrencies.map((currency) => {
        return {
          value: currency.id,
          label: `${currency.code} - ${currency.name}`,
          icon: currency.flag,
        };
      })
    );

    methods.setValue(
      "currency",
      baseCurrencies.find((currency) => currency.default)?.id
    );
    setCurrentCurrency(baseCurrencies.find((currency) => currency.default));
  }, [baseCurrencies]);

  return (
    <>
      <div className="flex flex-row items-center mb-4">
        <div className="w-6 h-6">
          <img src={AmountIcon} alt="" />
        </div>
        <div className="ml-3">
          {type === "vendor" ? "Paid" : "Received"} Amount
        </div>
      </div>
      <div>
        <MonetaryWithCurrencyInput
          name="paid_amount"
          currency={currentCurrency}
          currencies={currencies}
          onChangeCurrency={onChangeCurrency}
          serverErrors={error}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    baseCurrencies: state.Currencies.baseCurrencies,
    currentCompany: state.Company.currentCompany,
  };
};
export default connect(mapStateToProps, { setBaseCurrencies })(
  AmountWithCurrency
);
