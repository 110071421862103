import { NEXT_STEP, PREV_STEP, SET_SIGNUP_CONTACT } from "./type";

export const goNext = () => {
  return {
    type: NEXT_STEP,
  };
};

export const goPrev = () => {
  return {
    type: PREV_STEP,
  };
};

export const setSignupContact = (contact, contactType) => {
  return {
    type: SET_SIGNUP_CONTACT,
    contact: contact,
    contactType: contactType,
  };
};
