import * as yup from "yup";

export default (type) => {
  const typeOfDocument = type === "payment" ? "bill" : "invoice";
  const typeOfPartner = type === "payment" ? "vendor" : "customer";
  const typeOfAmount = type === "payment" ? "paid" : "received";
  return yup
    .object({
      paid_amount: yup
        .number()
        .required("This field is required")
        .transform((val, value) => {
          return value === "" ? 0.0 : val;
        })
        .typeError("Amount must be a number")
        .test("format", "Number with maximum 2 decimal point", (value) => {
          return /^-*[0-9,]+([.][0-9]{0,2})?$/.test(value || 0.0);
        })
        .max("100000000")
        .test("Is Zero?", "Amount can’t be zero", (value) => value !== 0)
        .positive("Amount can’t be a negative number"),
      payment_method: yup
        .number()
        .nullable()
        .required("This field is required")
        .positive("You should select payment method"),
      document_date: yup
        .string()
        .transform((value) => (value ? value : ""))
        .required("This field is required"),
      description: yup
        .string()
        .transform((value) => (value ? value : null))
        .nullable()
        .min(10)
        .max(160),
      outstandings: yup.array().of(
        yup.object().shape({
          outstandingId: yup.string().required("Id is required"),
          outstandingAmount: yup
            .number()
            .required("This field is required")
            .transform((val, value) => {
              return value === "" ? 0.0 : val;
            })
            .typeError("Amount must be a number")
            .test("format", "Invalid Number", (value) => {
              return /^-*[0-9,]+([.][0-9]{0,2})?$/.test(value || 0.0);
            })
            .max("100000000")
            .test("Is Zero?", "Amount can’t be zero", (value) => value !== 0)
            .positive("Amount can’t be a negative number"),
          amountOfOutstanding: yup
            .bool()
            .when(["amount", "outstandingAmount"], {
              is: (amount, outstandingAmount) => outstandingAmount > amount,
              then: yup
                .bool()
                .required(
                  `The added amount can’t exceed the ${typeOfDocument}’s original amount`
                ),
              otherwise: yup.bool(),
            }),
        })
      ),
      amountOfOutstandings: yup.bool().when(["paid_amount", "outstandings"], {
        is: (amount, outstandings) =>
          outstandings.reduce(
            (partialSum, a) => partialSum + a.outstandingAmount,
            0
          ) > amount,
        then: yup
          .bool()
          .required(
            `The total added amounts can’t exceed the ${typeOfAmount} amount`
          ),
        otherwise: yup.bool(),
      }),
      vendorOrDescripation: yup.bool().when(["partner", "description"], {
        is: (vendor, description) => !vendor && !description,
        then: yup
          .bool()
          .required(
            `Please add a description or select a ${typeOfPartner} in order to proceed`
          ),
        otherwise: yup.bool(),
      }),
    })
    .required();
};
