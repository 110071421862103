import axios from "axios";
import { eraseCookie, getCookie } from "../utils";
import { ShouldResetPassword, ShouldVerifyAccount } from "../exceptions";
import Auth from "./Auth";
const API_ROOT_URL = process.env.REACT_APP_API_ROOT_URL;

axios.interceptors.request.use((req) => {
  // `req` is the Axios request config, so you can modify
  // the `headers`.
  if (req.url.includes("login")) {
    return req;
  }
  const savedJwt = getCookie("jwt");
  if (savedJwt) req.headers.authorization = `Bearer ${savedJwt}`;
  return req;
});
const request = (path, options = {}) => {
  // Send a POST request
  return axios({
    url: path,
    ...options,
  });
};
const get = (path, options = {}) => {
  return request(path, {
    method: "GET",
    ...options,
  });
};

const post = (path, data, options = {}) => {
  return request(path, {
    method: "POST",
    data: data,
    ...options,
  });
};

const put = (path, data, options = {}) => {
  return request(path, {
    method: "PUT",
    body: data,
    ...options,
  });
};

const del = (path, data, options = {}) => {
  return request(path, {
    method: "DELETE",
    body: data,
    ...options,
  });
};
const transformPath = (path) => {
  return API_ROOT_URL + path;
};
const handlerError = (err) => {
  let message;
  if (err?.response?.status) {
    switch (err.response.status) {
      case 439:
        throw new ShouldResetPassword();
      case 440:
        throw new ShouldVerifyAccount();
      case 404:
        message =
          "Sorry! something went wrong, please contact our support team";
        break;
      case 500:
        message =
          "Sorry! something went wrong, please contact our support team";
        break;
      case 401:
        message = err?.response?.data || "Invalid credentials";
        Auth.logout();
        if (!err?.response?.config?.url?.endsWith("/user-management/login/")) {
          window.location.reload();
        }
        break;
      default:
        message = err?.response?.data || "Sorry! something went wrong";
        break;
    }
  } else {
    message = err.message || "Sorry! something went wrong";
  }

  if (typeof message !== "object") {
    message = { detail: message };
  }
  return message;
};
// getHttpClient returns an http client
export function getHttpClient() {
  return {
    get: async (path, options) => {
      try {
        const response = await get(transformPath(path), options);
        return response;
      } catch (err) {
        throw handlerError(err);
      }
    },

    post: async (path, data, options) => {
      try {
        const response = await post(transformPath(path), data, options);
        return response;
      } catch (err) {
        throw handlerError(err);
      }
    },
    put: async (path, data, options) => {
      try {
        const response = await put(transformPath(path), data, options);
        return response;
      } catch (err) {
        throw handlerError(err);
      }
    },
    del: async (path, data, options) => {
      try {
        const response = await del(transformPath(path), data, options);
        return response;
      } catch (err) {
        throw handlerError(err);
      }
    },
  };
}
export default getHttpClient;
