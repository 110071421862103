import getHttpClient from "../api";
const client = getHttpClient();
const baseUrl = "/user-verification";
export default {
  verifyMobile: async (mobile, validate_contact = true) => {
    try {
      const data = { mobile: mobile, validate_contact: validate_contact };
      const response = await client.post(`${baseUrl}/mobile/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (err) {
      throw err;
    }
  },

  resendVerifyMobile: async (mobile, validate_contact = true) => {
    try {
      const data = { mobile: mobile, validate_contact: validate_contact };
      const response = await client.post(`${baseUrl}/mobile/resend/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (err) {
      throw err;
    }
  },
  verifyEmail: async (email, validate_contact = true) => {
    try {
      const data = { email: email, validate_contact: validate_contact };
      const response = await client.post(`${baseUrl}/email/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (err) {
      throw err;
    }
  },

  resendVerifyEmail: async (email, validate_contact = true) => {
    try {
      const data = { email: email, validate_contact: validate_contact };
      const response = await client.post(`${baseUrl}/email/resend/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (err) {
      throw err;
    }
  },
  verifyCode: async (contact, code) => {
    try {
      const data = { token: code, contact: contact };
      const response = await client.post(`${baseUrl}/token/validate/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (err) {
      throw err;
    }
  },
};
