import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";

import ResetPasswordService from "../../../services/ResetPassword";
import PasswordInput from "../../Inputs/Password";
import SubmitButton from "../../Buttons/Submit";
import ErrorMessage from "../../ErrorMessage";
import { getToastSuccessProps } from "../../../utils";

const schema = yup
  .object({
    password: yup
      .string()
      .required("This field is required")
      .min(8, "at least 8 alphanumeric"),
    confirm_password: yup
      .string()
      .required("This field is required")
      .min(8, "at least 8 alphanumeric")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();
const ResetPassword = ({ token, type }) => {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    try {
      let response = null;
      if (type === "invitation") {
        response = await ResetPasswordService.setPassword(
          token,
          data?.password,
          data?.confirm_password
        );
      } else {
        response = await ResetPasswordService.resetPassword(
          token,
          data?.password,
          data?.confirm_password
        );
      }
      toast.success("Password Changed Successfully", {
        ...getToastSuccessProps(),
      });
      navigate("/login");
    } catch (error) {
      setError(error);
    }
  };
  return (
    <>
      <div className="font-bold	text-2xl leading-9 font-poppins">
        Create new password
      </div>
      <div className="text-base text-grayColor leading-5 mt-3 font-roboto font-light">
        Your new password must be different from your previous passwordccount.
      </div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex flex-col">
            <div className="flex mt-10">
              <PasswordInput
                name="password"
                serverErrors={error}
                placeholder="Password"
              />
            </div>

            <div className="flex mt-4">
              <PasswordInput
                name="confirm_password"
                serverErrors={error}
                placeholder="Confirm Password"
              />
            </div>
            {error?.detail && <ErrorMessage>{error.detail}</ErrorMessage>}
            <div className="mt-8">
              <SubmitButton disabled={methods.formState.isSubmitting}>
                Reset password
              </SubmitButton>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  return { token: state.resetPassword.token };
};
export default connect(mapStateToProps, {})(ResetPassword);
