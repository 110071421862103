import errorIcon from "../../assets/imgs/icons/errorIcon.svg";
import cancelRed from "../../assets/imgs/icons/cancelRed.svg";

const GlobalErrorMessage = ({ children }) => {
  return (
    <div className="w-full bg-errorBackground p-3 my-2 text-sm	font-medium leading-6 bg-opacity-10">
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row items-center">
          <img src={errorIcon} alt="" />
          <div className="ml-2">{children}</div>
        </div>
        <div>
          <img src={cancelRed} alt="" />
        </div>
      </div>
    </div>
  );
};

export default GlobalErrorMessage;
