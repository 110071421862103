import React, { useState, useEffect } from "react";
import { convertFileToBase64 } from "../../utils";
const ImageViewer = ({ image }) => {
  const [url, setUrl] = useState(image);
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="flex justify-center items-center max-w-630 max-h-552 bg-white">
        <img src={url} alt="" className="w-full h-full max-w-630 max-h-552" />
      </div>
    </div>
  );
};
export default ImageViewer;
