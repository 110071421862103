import { useFormContext } from "react-hook-form";
import ErrorMessage from "../../ErrorMessage";
import checkIcon from "../../../assets/imgs/icons/check.svg";
import ErrorInput from "../Error";
import _ from "lodash";
const RadioInput = ({ id, name, icon, children, serverErrors, ...props }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <div className="w-full">
      <div class="flex items-center">
        <input
          id={id}
          type="radio"
          name={name}
          class="hidden"
          {...register(name)}
          {...props}
        />
        <label for={id} class="flex items-center cursor-pointer text-xl">
          <div className="mr-4">
            <span className="w-5 h-5 inline-block rounded-full border border-grey flex-no-shrink"></span>
            <img src={checkIcon} alt="" className="hidden" />
          </div>
          <div className="mr-5">
            <img src={icon} alt="" />
          </div>
          <div>{children}</div>
        </label>
      </div>
      <ErrorInput name={name} errors={errors} serverErrors={serverErrors} />
    </div>
  );
};

export default RadioInput;
