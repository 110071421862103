import Select, { components, OptionProps } from "react-select";
import RadioButtonCheckedIcon from "../../../assets/imgs/icons/radio_button_checked.svg";
const Option = (props) => {
  const { isSelected, data, children } = props;
  return (
    <div>
      <components.Option {...props}>
        <div className="flex flex-row justify-between items-center">
          <div class="flex flex-row items-center place-content-start cursor-pointer text-xl">
            <div className="mr-2">
              {!isSelected && (
                <span className="inline-block w-4 h-4 border rounded-full border-grey flex-no-shrink"></span>
              )}
              {isSelected && <img src={RadioButtonCheckedIcon} alt="" />}
            </div>
            {data?.icon && (
              <div className="mr-2">
                <img src={data?.icon} alt="" />
              </div>
            )}
            <div className="text-sm leading-3">{children}</div>
          </div>
          <div className="text-sm leading-3">{data?.text}</div>
        </div>
      </components.Option>
    </div>
  );
};
export default Option;
