import { useEffect, useState, useRef } from "react";
import addUserIcon from "../../assets/imgs/icons/add user.svg";
import ArrowBottomIcon from "../../assets/imgs/icons/arrow_drop_down.svg";
import SearchIcon from "../../assets/imgs/icons/search.svg";

const AssignToSomeone = ({ options, onChange }) => {
  const [isOpen, setIsOpen] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [firstName, setFirstName] = useState("");
  const [countSelected, setCountSelected] = useState(0);
  const [allChecked, setAllChecked] = useState();
  const [virtualOptions, setVirtualOptions] = useState([]);
  const [showedOptions, setShowedOptions] = useState([]);

  const ref = useRef(null);
  const onClickOutside = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);

  const toggleAll = (e) => {
    setVirtualOptions(
      virtualOptions.map((option) => {
        return showedOptions.includes(option)
          ? { ...option, checked: e.target.checked }
          : { ...option };
      })
    );
  };
  const toggleOption = (e, optionId) => {
    setVirtualOptions(
      virtualOptions.map((option) => {
        return option.value === optionId
          ? { ...option, checked: e.target.checked }
          : { ...option };
      })
    );
  };

  const searchOption = (e) => {
    const key = e.target.value;
    setSearchValue(key);
    setShowedOptions(
      virtualOptions.filter((option) =>
        option.label.toLowerCase().includes(key.toLowerCase())
      )
    );
  };
  const isAllChecked = () => {
    return virtualOptions.every((option) => option.checked);
  };
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    setAllChecked(isAllChecked);
    setShowedOptions(virtualOptions);
    setSearchValue("");
    const checkedList = virtualOptions.filter((option) => option.checked);
    if (onChange) {
      onChange(checkedList.map((option) => option.value));
    }
    if (checkedList.length) {
      setFirstName(checkedList[0].label);
      setCountSelected(checkedList.length);
    } else {
      setCountSelected(0);
    }
  }, [virtualOptions]);

  useEffect(() => {
    setVirtualOptions(
      options.map((option) => {
        return { ...option, checked: false };
      })
    );
  }, [options]);
  return (
    <div className="relative">
      <div
        className="flex flex-row items-center justify-center cursor-pointer"
        onClick={toggleOpen}
      >
        <div className="p-1">
          <img src={addUserIcon} alt="" />
        </div>
        <div className="flex flex-row items-center">
          Assign to {countSelected === 0 && <div className="ml-1">someone</div>}
          {countSelected > 0 && (
            <div className="ml-1 font-bold">{firstName}</div>
          )}{" "}
          {countSelected > 1 && (
            <div className="w-7 h-4 text-sm text-white bg-arrowTop rounded-lg font-roboto flex justify-center ml-1">{`+${
              countSelected - 1
            }`}</div>
          )}
        </div>
        <div>
          <img src={ArrowBottomIcon} alt="" />
        </div>
      </div>
      {isOpen && (
        <div
          class="absolute py-2 bg-white rounded-md shadow-dropdownMenu overflow-hidden z-20"
          ref={ref}
          style={{ width: "20rem", bottom: 40 }}
        >
          <div className="flex flex-row w-full h-full px-2 border-b-2">
            <img src={SearchIcon} />
            <input
              className="w-full px-2 py-2 ml-2 border-0 focus:outline-none"
              placeholder="Search"
              value={searchValue}
              onChange={searchOption}
            />
          </div>
          <div className="overflow-auto max-h-60">
            {showedOptions.length === 0 && (
              <div className="py-4 text-sm font-roboto">
                <div className="flex flex-row justify-center">
                  <div>No Option</div>
                </div>
              </div>
            )}
            {showedOptions.length > 0 && (
              <div>
                <div className="py-4 text-sm font-roboto">
                  <div className="flex flex-row">
                    <div className="px-4">
                      <input
                        type="checkbox"
                        onChange={toggleAll}
                        checked={allChecked}
                      />
                    </div>
                    <div>All</div>
                  </div>
                </div>
                {showedOptions.map((option) => (
                  <div className="py-4 text-sm font-roboto">
                    <div className="flex flex-row">
                      <div className="px-4">
                        <input
                          type="checkbox"
                          checked={option.checked}
                          onChange={(e) => {
                            return toggleOption(e, option.value);
                          }}
                        />
                      </div>
                      <div>{option.label}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AssignToSomeone;
