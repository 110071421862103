import { SET_BASE_CURRENCIES } from "./type";

export const initialState = {
  baseCurrencies: [],
};

const currencies = (state = initialState, action) => {
  switch (action.type) {
    case SET_BASE_CURRENCIES:
      state = {
        ...state,
        baseCurrencies: action.currencies,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default currencies;
