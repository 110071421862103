import { useState } from "react";
import { connect } from "react-redux";
import CircleProgressBar from "../../components/CircleProgressBar";
import SendCodeToMobile from "../../components/Forms/SendCodeToMobile";
import SendCodeToEmail from "../../components/Forms/SendCodeToEmail";
import CodeVerification from "../../components/Forms/CodeVerification";
import SignupForm from "../../components/Forms/Signup";
import Users from "../../services/users";
import Logo from "../../assets/imgs/logoTheaccounter.png";
import { goNext, goPrev } from "../../store/signup/actions";
const Signup = ({ step, signUpContact, signUpType, goNext, goPrev }) => {
  const [type, setType] = useState("email");
  const toggleType = () => {
    if (type === "mobile") {
      setType("email");
    } else {
      setType("mobile");
    }
  };
  return (
    <section className="flex flex-col mx-16 pt-12 h-screen justify-between	">
      <div className="flex flex-row">
        <img src={Logo} alt="" />
      </div>
      <div className="flex flex-col items-center justify-center mt-6 mb-auto">
        <div className="w-425 flex flex-col p-1">
          <CircleProgressBar step={step} />
          <div className="font-bold	text-3xl leading-9 font-poppins">
            Registration
          </div>
          <div className="text-base text-grayColor leading-5 mt-3 font-roboto font-light	">
            We're so happy you're here, let’s start by signing up.
            <br />
            Fill in the registration data. It will take a couple of minutes.
          </div>
          {step === 0 && type === "mobile" && (
            <SendCodeToMobile toggleType={toggleType} />
          )}
          {step === 0 && type === "email" && (
            <SendCodeToEmail toggleType={toggleType} />
          )}
          {step === 1 && (
            <CodeVerification
              contact={signUpContact}
              type={signUpType}
              goNext={goNext}
              goPrev={goPrev}
            />
          )}
          {step === 2 && <SignupForm />}
        </div>
      </div>

      <div className="w-full justify-self-end mt-6 mb-1.875">
        <div className="text-center">
          Copyright © 2021 The Accounter - All Rights Reserved.
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    step: state.signup.step,
    signUpContact: state.signup.signUpContact,
    signUpType: state.signup.signUpType,
  };
};
export default connect(mapStateToProps, { goNext, goPrev })(Signup);
