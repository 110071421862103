import { toast } from "react-toastify";

import {
  getToastErrorProps,
  getToastSuccessGreenProps,
  getToastSuccessProps,
} from "./utils";

const ToastifyError = (child, options = {}) => {
  toast.dismiss();
  toast.error(child, { ...getToastErrorProps(), ...options });
};

const ToastifySuccessGreen = (child) => {
  toast.dismiss();
  toast.success(child, { ...getToastSuccessGreenProps() });
};
const ToastifySuccess = (child) => {
  toast.dismiss();
  toast.success(child, {
    ...getToastSuccessProps(),
  });
};

export { ToastifyError, ToastifySuccess, ToastifySuccessGreen };
