import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import mammoth from "mammoth";

import { convertUrlToFile } from "../../utils";

const DocViewer = ({ word }) => {
  return (
    <div className="bg-white">
      <div className="w-full max-h-693 overflow-y-auto">
        <iframe
          id="msdoc-iframe"
          title="msdoc-iframe"
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
            word
          )}`}
          frameBorder="0"
          height="640"
          className="w-full"
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, {})(DocViewer);
