import { useCallback } from "react";

import useCancellableModal from "./useCancellableModal";

export default function useModal(ModalComponent) {
  const callCancellableModal = useCancellableModal(ModalComponent);

  return useCallback(
    async (props) => await callCancellableModal(props)[0],
    [ModalComponent]
  );
}
