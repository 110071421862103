import { getCookie } from "../../utils";
import { SET_ALLOWED_COMPANIES, SET_CURRENT_COMPANY } from "./type";
const user = JSON.parse(getCookie("user"));
const companies = user?.companies;
const currentCompany = companies?.find(
  (company) =>
    company.id === (parseInt(localStorage.getItem("company")) || user?.company)
);
export const initialState = {
  currentCompany: currentCompany,
  allowedCompanies: companies,
};

const Company = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_COMPANY:
      state = {
        ...state,
        currentCompany: action.company,
      };
      break;
    case SET_ALLOWED_COMPANIES:
      state = {
        ...state,
        allowedCompanies: action.companies,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default Company;
