import { useState } from "react";
import { connect } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import SubmitButton from "../../Buttons/Submit";

import { goNext, setSignupContact } from "../../../store/signup/actions";
import Verification from "../../../services/Verification";
import MobileInput from "../../Inputs/Mobile";
import validateMobileInput from "../../Inputs/Mobile/validation";
import ErrorMessage from "../../ErrorMessage";

const schema = yup
  .object({
    mobile: validateMobileInput,
  })
  .required();
const SendCodeToMobile = ({ step, goNext, toggleType, setSignupContact }) => {
  const [error, setError] = useState("");
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const handleSendCode = async (data) => {
    try {
      const userMobile = "+971" + data?.mobile;
      const { mobile } = await Verification.verifyMobile(userMobile);
      setSignupContact(userMobile, "mobile");
      goNext();
    } catch (error) {
      setError(error);
    }
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSendCode)}>
        <div className="mt-9 text-sm font-normal	">Enter your phone number</div>
        <div className="mt-8">
          <MobileInput name="mobile" serverErrors={error} />
        </div>
        {error?.detail && <ErrorMessage>{error.detail}</ErrorMessage>}
        <div className="mt-10">
          <SubmitButton disabled={methods.formState.isSubmitting}>
            Send Code
          </SubmitButton>
        </div>

        <div
          className="mt-8 text-arrowRigh cursor-pointer"
          onClick={toggleType}
        >
          Register using e-mail
        </div>
      </form>
    </FormProvider>
  );
};
const mapStateToProps = (state) => {
  return {
    step: state.signup.step,
  };
};
export default connect(mapStateToProps, {
  goNext,
  setSignupContact,
})(SendCodeToMobile);
