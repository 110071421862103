import react from "react";
import { Route, Navigate, useLocation } from "react-router-dom";
import { getCookie } from "../utils";
import Home from "../pages/Home";
import NewRequest from "../pages/NewRequest";
import Signup from "../pages/Signup";
import Login from "../pages/Login";
import ResetPassword from "../pages/ResetPassword";
import UploadDocument from "../components/Forms/UploadDocument";
import CreatePayment from "../components/Forms/CreatePayment";
import CreateReceipt from "../components/Forms/CreateReceipt";
import VerifyAccount from "../pages/VerifyAccount";
import CreateSalaries from "../components/Forms/CreateSalaries";
import AcceptInvitation from "../pages/AcceptInvitation";

const authProtectedRoutes = [
  { path: "/", component: Home },
  {
    path: "/new-request",
    component: NewRequest,
    childrens: [
      { path: "upload", component: UploadDocument },
      { path: "create-payment", component: CreatePayment },
      { path: "create-receipt", component: CreateReceipt },
      { path: "create-salaries", component: CreateSalaries },
    ],
  },
];

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/signup", component: Signup },
  { path: "/resetPassword", component: ResetPassword },
  { path: "/verifyAccount", component: VerifyAccount },
  { path: "/user-invitation/:token", component: AcceptInvitation },
];

function RequireAuth({ children }) {
  const savedJwt = getCookie("jwt");
  let location = useLocation();

  if (!savedJwt) {
    if (location.pathname === "/") {
      return <Navigate to="/login" />;
    } else {
      return <Navigate to="/login" state={{ from: location }} />;
    }
  }

  if (!children) {
    return <Navigate replace to="/" />;
  }

  return children;
}

function RequireNonAuth({ children }) {
  const savedJwt = getCookie("jwt");
  let location = useLocation();
  if (savedJwt) {
    if (location.pathname === "/login") {
      return <Navigate to="/" />;
    }
  }

  return children;
}
export { authProtectedRoutes, publicRoutes, RequireAuth, RequireNonAuth };
