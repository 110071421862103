import { useFormContext } from "react-hook-form";
import { useEffect, useState, useRef } from "react";
import ErrorMessage from "../../ErrorMessage";
import SubmitButton from "../../Buttons/Submit";
import CodeField from "./codeField";
import ErrorInput from "../Error";

const CodeVerification = ({
  name,
  length,
  onChange,
  reset,
  setReset,
  serverErrors,
}) => {
  const [code, setCode] = useState([...Array(length)].map(() => ""));
  const inputs = useRef([]);

  const processInput = (e, slot) => {
    const num = e.target.value;
    if (/[^0-9]/.test(num)) return;
    const newCode = [...code];
    newCode[slot] = num;
    setCode(newCode);
    console.log(inputs);
    if (slot !== length - 1) {
      inputs.current[slot + 1].focus();
    }
  };

  const onKeyUp = (e, slot) => {
    if (e.keyCode === 8 && !code[slot] && slot !== 0) {
      const newCode = [...code];
      newCode[slot - 1] = "";
      setCode(newCode);
      inputs.current[slot - 1].focus();
    }
  };
  const handlePaste = (event) => {
    event.clipboardData.items[0].getAsString((text) => {
      let code = text
        .split("")
        .filter((char) => {
          if (!isNaN(char)) {
            return char;
          }
        })
        .map((char) => {
          return Number(char);
        });
      if (code.length < 4) {
        const codeComplete = new Array(4 - code.length).fill("");
        code = [...code, ...codeComplete];
      }
      setCode(code.slice(0, 4));
    });
  };
  useEffect(() => {
    onChange(code.join(""));
  }, [code]);

  useEffect(() => {
    if (reset) {
      setCode([...Array(length)].map(() => ""));
    }

    setReset(false);
  }, [reset]);
  return (
    <div className="flex justify-between mt-5" onPaste={handlePaste}>
      {code.map((num, idx) => {
        return (
          <input
            type="text"
            maxLength="1"
            max="9"
            min="0"
            className="h-15 w-15 text-xl text-center"
            key={idx}
            value={num}
            autoFocus={!code[0].length && idx === 0}
            onChange={(e) => processInput(e, idx)}
            onKeyUp={(e) => onKeyUp(e, idx)}
            ref={(ref) => {
              return inputs.current.push(ref);
            }}
          />
        );
      })}

      <ErrorInput name={name} errors={{}} serverErrors={serverErrors} />
    </div>
  );
};

export default CodeVerification;
