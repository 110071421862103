import { useFormContext } from "react-hook-form";
import ErrorMessage from "../../ErrorMessage";
import arrowDropDown from "../../../assets/imgs/icons/arrow_drop_down.svg";
import uea from "../../../assets/imgs/flags/uea.svg";
import ErrorInput from "../Error";
import _ from "lodash";

const MobileInput = ({ name, serverErrors, ...props }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row h-11 w-full">
        <div className="w-122 flex flex-row border border-borderColor justify-between p-2 items-center h-full rounded">
          <img className="rounded" src={uea} alt="" />
          <span className="text-base font-medium leading-7">+971</span>
          <img className="" src={arrowDropDown} alt="" />
        </div>
        <div className="w-3/4 ml-2.5 flex">
          <input
            type="tel"
            className={`w-full mb-1 placeholder:text-inputGrayColor placeholder:text-sm ${
              _.get(errors, name)
                ? "text-errorColor border-errorColor focus:text-errorColor focus:border-errorColor focus:bg-white placeholder:text-errorColor"
                : ""
            }`}
            placeholder="Mobile Number"
            {...register(name, {})}
            {...props}
          />
        </div>
      </div>
      <div>
        <ErrorInput name={name} errors={errors} serverErrors={serverErrors} />
      </div>
    </div>
  );
};

export default MobileInput;
