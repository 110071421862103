import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";

import TextInput from "../../Inputs/Text";
import PasswordInput from "../../Inputs/Password";
import SubmitButton from "../../Buttons/Submit";
import Auth from "../../../services/Auth";
import ErrorMessage from "../../ErrorMessage";
import SocialButton from "../../Buttons/Social";
import googleIcon from "../../../assets/imgs/icons/google.svg";
import facebookIcon from "../../../assets/imgs/icons/facebook.svg";
import { ShouldResetPassword, ShouldVerifyAccount } from "../../../exceptions";
import ResetPasswordService from "../../../services/ResetPassword";
import VerificationService from "../../../services/Verification";
import {
  goNext,
  setStep,
  setResetPasswordContact,
} from "../../../store/resetPassword/actions";
import { setVerifyAccountContact } from "../../../store/verifyAccount/actions";
import { setCurrentUser } from "../../../store/User/actions";
import CheckBoxInput from "../../Inputs/CheckBox";
import { eraseCookie, getToastSuccessProps } from "../../../utils";
import { ToastifySuccess } from "../../../toastify";
const THEACCOUNTER_ENV = process.env.REACT_APP_THEACCOUNTER_ENV;

const schema = yup
  .object({
    contact: yup.string().required("This field is required"),
    password: yup.string().required("This field is required"),
  })
  .required();

const LoginForm = ({
  goNext,
  setResetPasswordContact,
  setStep,
  setVerifyAccountContact,
  setCurrentUser,
}) => {
  const [contact, setContact] = useState(false);
  const [shouldResetPassword, setShouldResetPassword] = useState(false);
  const [shouldVerifyAccount, setShouldVerifyAccount] = useState(false);
  const [error, setError] = useState("");
  let navigate = useNavigate();
  const location = useLocation();
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    try {
      await Auth.logout();
      const contact = data?.contact;
      setContact(contact);
      const password = data?.password;
      const rememberMe = data?.rememberMe;
      const user = await Auth.login(contact, password, rememberMe);
      setCurrentUser(user);
      navigate(location?.state?.from || "/");
    } catch (error) {
      if (error instanceof ShouldResetPassword) {
        setShouldResetPassword(1);
      } else if (error instanceof ShouldVerifyAccount) {
        setShouldVerifyAccount(1);
      } else {
        setError(error);
      }
    }
  };

  const handleUpdateAccount = async (event) => {
    try {
      event.preventDefault();
      const channel = contact.includes("@") ? "email" : "mobile";
      if (shouldResetPassword) {
        setStep(2);
        setResetPasswordContact(contact, channel);
        if (channel === "email") {
          const { email } = await ResetPasswordService.verifyEmail(contact);
        } else if (channel === "mobile") {
          const { mobile } = await ResetPasswordService.verifyMobile(contact);
        }
        ToastifySuccess(
          `we have sent a verification code to the ${channel} provided`
        );
        navigate("/resetPassword");
      } else if (shouldVerifyAccount) {
        setVerifyAccountContact(contact, channel);
        if (channel === "email") {
          const { email } = await VerificationService.verifyEmail(
            contact,
            false
          );
        } else if (channel === "mobile") {
          const { mobile } = await VerificationService.verifyMobile(
            contact,
            false
          );
        }
        toast.info(
          `we have sent a verification code to the ${channel} provided`,
          { ...getToastSuccessProps() }
        );
        navigate("/verifyAccount");
      }
    } catch (error) {
      setError(error);
    }
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {location?.state?.from ? (
          <ErrorMessage>Please Login to view this Page</ErrorMessage>
        ) : null}
        <div className="flex flex-col">
          <div className="flex">
            <TextInput
              name="contact"
              serverErrors={error}
              placeholder="Email or Mobile Number"
            />
          </div>

          <div className="flex mt-4">
            <PasswordInput
              name="password"
              serverErrors={error}
              placeholder="Password"
            />
          </div>
          {(shouldResetPassword || shouldVerifyAccount) && (
            <div
              className="flex flex-col mt-4 justify-between font-roboto rounded"
              style={{ backgroundColor: "rgba(37, 171, 195, 0.16)" }}
            >
              <div className="m-4">
                <div className="text-sm font-bold">Update your account</div>
                <div className="text-sm">
                  To help ensure your account's security and receive quick
                  support
                </div>
                <div className="mt-4 ml-4 text-sm font-medium">
                  <ul className="list-disc">
                    <li>Verify your email address or mobile number</li>
                    {shouldResetPassword && <li>Update your password</li>}
                  </ul>
                </div>
                <div className="mt-4">
                  To continue{" "}
                  <a href="" className="link" onClick={handleUpdateAccount}>
                    click here
                  </a>
                </div>
              </div>
            </div>
          )}
          <div className="flex justify-between mt-4">
            <div>
              <CheckBoxInput
                name="rememberMe"
                serverErrors={error}
                label="Keep me logged in"
              />
            </div>
            <div className="text-sm font-normal ">
              <Link to="/resetPassword">Forgot Password?</Link>
            </div>
          </div>
          {error?.detail && <ErrorMessage>{error.detail}</ErrorMessage>}
          <div className="mt-8">
            <SubmitButton disabled={methods.formState.isSubmitting}>
              Login
            </SubmitButton>
          </div>

          {THEACCOUNTER_ENV === "development" && (
            <div className="flex justify-center mt-8">
              <div className="text-sm font-normal">Don’t have an account?</div>
              <div
                className="ml-2 cursor-pointer text-arrowRigh"
                onClick={() => {
                  navigate("/signup");
                }}
              >
                Sign up
              </div>
            </div>
          )}
          {THEACCOUNTER_ENV === "development" && (
            <div className="flex flex-col items-center justify-center mt-8">
              <div className=" w-327">
                <div className="flex items-center justify-center">
                  <span class="bg-borderColor h-px flex-grow mr-8"></span>
                  <span class="flex uppercase text-base">OR</span>
                  <span class="bg-borderColor h-px flex-grow ml-8"></span>
                </div>
                <div className="mt-8 ">
                  <SocialButton text="Sign in with Google" icon={googleIcon} />
                </div>
                <div className="mt-4">
                  <SocialButton
                    text="Sign in with Facebook"
                    icon={facebookIcon}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </form>
    </FormProvider> // pass all methods into the context
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
  goNext,
  setResetPasswordContact,
  setStep,
  setVerifyAccountContact,
  setCurrentUser,
})(LoginForm);
